// Polyfills
import 'core-js/actual/promise';
import 'core-js/actual/string/includes';
import 'core-js/actual/array/includes';

// Application start

import './startup/libraries'
import './startup/vue-addons'
import './startup/vue-filters'
import './startup/global-components'
import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
// don't remove this or Sentry will break and the app will crash
import Raven from 'raven-js';
// import firebase from 'firebase';

// Is this a non www. domain AND not the app?
if (location.hostname.indexOf('topsripitwinit') >= 0) {
  if (!process.env.VUE_APP_APP_VERSION && !/^www|qa\./.test(location.hostname)) {
    location.href = `https://www.${location.hostname}${location.pathname}${location.hash}`;
  }
}

Vue.config.productionTip = false;
// if (!top.location.href.match('localhost:8080')) {
// Initialize Firebase
// let config = {
//     apiKey:            "AIzaSyCy69w5gBJA3Yv1_Avzd--_MacS7w_LNyg",
//     authDomain:        "notificationsdemo-24583.firebaseapp.com",
//     databaseURL:       "https://notificationsdemo-24583.firebaseio.com",
//     projectId:         "notificationsdemo-24583",
//     storageBucket:     "notificationsdemo-24583.appspot.com",
//     messagingSenderId: "170437207150"
// };
// firebase.initializeApp(config);
// }
/* eslint-disable no-new */


let startup = function () {
  new Vue({
	router,
	store,
	render: (h: any) => h(App)
  } as any).$mount('#app')
};

if (process.env.VUE_APP_APP_VERSION) {
  Raven.context(startup);
} else {
  startup();
}
