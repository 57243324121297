<template>
    <div class="container-fluid">
        <h1 class="text-center page-title">Collect & Win</h1>
        <h3 v-if="busy" class="text-center">One moment...</h3>
        <template v-else>
            <p class="mx-auto text-center mb-3 mb-md-5 lead">Certain game tickets will contain an online code to enter and collect a Railroad Property (Reading, Pennsylvania, B&O and Short Line) or Utility Property (Electric Company and Water Works). Collect ALL 4 Railroads to win $25 Grocery Cash. Collect both Electric Company and Water Works to win $10 Grocery Cash. Earned properties are stored in a player’s online Game Account. $10 and $25 Grocery Cash will be paid as eCoupons which will be available to click and load to their Tops BonusPlus card once all property markers are collected.  Offer valid through June 30, 2023.  There is a limit of one (1) $10 Grocery Cash prize and one (1) $25 Grocery Cash prize per registered BonusPlus account.</p>
            <div class="bg-white p-4 p-xl-5 text-center">

                <div class="d-flex justify-content-center align-items-center game-cards flex-wrap">
                    <span class="m-1" :class="{owned: codes.includes('Reading Railroad')}"><img :src="require(`../assets/images/m-reading-railroad.png`)"
                                                                                                alt="Reading Railroad"></span>
                    <span class="m-1" :class="{owned: codes.includes('Pennsylvania Railroad')}"><img :src="require(`../assets/images/m-penn-railroad.png`)"
                                                                                                     alt="Pennsylvania Railroad"></span>
                    <span class="m-1" :class="{owned: codes.includes('B&O Railroad')}"><img :src="require(`../assets/images/m-bo-railroad.png`)" alt="B & 0 Railroad"></span>
                    <span class="m-1" :class="{owned: codes.includes('Short Line Railroad')}"><img :src="require(`../assets/images/m-shortline.png`)" alt="Short Line"></span>
                </div>

                <p class="h4 mt-4" style="text-transform: none">You've collected {{ numberRailroadProperties }} of 4 Railroad Properties.</p>
                <p>
                    Collect all 4 Railroads to win $25 Grocery Cash. See rules for complete details.<br>
                    <strong>LIMIT ONE PER ACCOUNT.</strong>
                </p>

            </div>

            <div class="bg-white p-4 p-xl-5 my-4 text-center flex-wrap">

                <div class="d-flex justify-content-center align-items-center game-cards">
                    <span class="m-1" :class="{owned: codes.includes('Electric Company')}"><img :src="require(`../assets/images/m-electric-company.png`)" alt="Electric Company"></span>
                    <span class="m-1" :class="{owned: codes.includes('Water Works')}"><img :src="require(`../assets/images/m-water-works.png`)" alt="Water Works"></span>
                </div>

                <p class="h4 mt-4" style="text-transform: none">You've collected {{ numberUtilityProperties }} of 2 Utility Properties.</p>
                <p>
                    Collect both Electric Company and Water Works to win $10 Grocery Cash. See rules for complete details.<br>
                    <strong>LIMIT ONE PER ACCOUNT.</strong>
                </p>
            </div>
            <!-- <page-sub-section :cols="['history-left', 'faq']"></page-sub-section> -->
        </template>
    </div>
</template>

<script>
	import { get } from "vuex-pathify";
	import vuexStore from "../store";

	export default {
		name: "magic-tokens",

		data() {
			return {
			    busy: true,
			    codes:[]
            };
		},
		computed: {
		    numberRailroadProperties() {
		        let a = ['Reading Railroad', 'Pennsylvania Railroad', 'B&O Railroad', 'Short Line Railroad'];
		      return this.howManyOf(a);
            },
            numberUtilityProperties() {
                let a = ['Electric Company', 'Water Works'];
                return this.howManyOf(a);
            },
			isClipable() {
				if (!(this.$store.get("auth/numberOfTokens") > 0)) {
					return false;
				}
				if (this.coupon.noclip) {
					return false;
				}
				return true;
			},
			coupon() {
				return this.$store.get("auth/sessionData@weeklyCoupon");
			},
			imageUrl() {
				return this.coupon.image_url;
			},
			headline() {
				return this.coupon.headline || `This Week's Offer`;
			},
		},
        created() {

            this.$http.get("/game/collectAndWin").then(
                response => {
                    this.codes = response.data;
                },
                () => {
                }
            ).finally(()=>{
                this.busy=false
            });
        },

		mounted() {
			this.watchConnectivity(true);
		},
		beforeDestroy() {
			this.watchConnectivity(false);
		},

		methods: {
            howManyOf(a)
            {
                return this.codes.reduce((x, n)=>{
                    if (a.includes(n))
                        x++;
                    return x;
                }, 0)
            },
			spend() {
				this.$modalService
					.create("confirm", {
						message: `You are about to redeem 1 Magic Token for this week's offer.`,
					})
					.setReturnElement(this.returnFocusEl)
					.on("continue", this.doSpendToken);
			},
			showError(xhrError) {
				this.$modalService.close();
				this.$modalService.create("generic", {
					title: "Sorry...",
					message:
						"Something went wrong and the coupon could not be added. Please try again in a few minutes.",
				});
			},
			async doSpendToken() {
				let { data } = await this.$http
					.post("/game/redeemPrizeWithTokens")
					.catch(this.showError);
				if (data) {
					this.$store.set("auth/tokensAvailable", data.tokensAvailable);
					await this.$store.dispatch("auth/refreshUser");
					this.$modalService.close();
					this.$modalService.create("codeEntry/generic", data.modal);
				}
			},
		},
		beforeRouteEnter(to, from, next) {
			if (!vuexStore.get("auth/allowSpendTokens")) {
				next({ name: "home" });
				return;
			}

			next();
		},
		watch: {},
	};
</script>

<style lang="scss" scoped>
	.game-cards {
		span {
			background-color: $black;
			position: relative;
			display: inline-block;
			&.owned {
				img {
					opacity: 1;
				}
				&:after {
					content: "";
					position: absolute;
					bottom: 9px;
					right: 9px;
					width: 25px;
					height: 25px;
					background-image: url("../assets/images/check.svg");
				}
			}
		}
		img {
			position: relative;
			opacity: 0.5;
		}
	}
</style>
