<template>
    <div class="form-group" v-if="useTextChallenge">
        <img class="img-responsive" :src="imageUrl">
        <button type="button" class="btn btn-primary btn-sm" @click="reset">
            <fa-icon icon="faSyncAlt" pack="solid" v-ada></fa-icon>
        </button>
        <br>
        <label>
            Type the characters in the box below:<br>
            <input type="text" class="form-control captcha-input" v-model="captchaCode"/>
        </label>
    </div>

    <div class="small my-3" v-else-if="hideBadge">
        This site is protected by reCAPTCHA and the Google
        <a href="https://policies.google.com/privacy" target="_blank" rel="noopener">Privacy Policy</a> and
        <a href="https://policies.google.com/terms" target="_blank" rel="noopener">Terms of Service</a> apply.
    </div>

</template>

<script>
import Vue from 'vue'
import {VueReCaptcha} from 'vue-recaptcha-v3'

export default {
    name:     "Recaptcha3",
    props:    {
        siteKey:          [Boolean, String],
        hideBadge:        Boolean,
        context:          String,
        useTextChallenge: {
            type:    Boolean,
            default: () => {
                return false;
            }
        }
    },
    computed: {
        imageUrl() {
            let uri = this.$config.serverUrl + '/app/generateCaptcha';
            if (this.time) {
                uri += '?t=' + this.time;
            }

            return uri;
        }
    },
    created() {
        // console.log('[Vue Recaptcha] useTextChallenge=',this.useTextChallenge);
        if (!this.siteKey) {
            console.error('[Vue Recaptcha] Cannot use reCAPTCHA because siteKey is missing');
            return;
        }
        if (!this.$recaptcha && !this.useTextChallenge) {
            // console.log('[Vue Recaptcha] created');
            Vue.use(VueReCaptcha, {
                siteKey:       this.siteKey,
                loaderOptions: {
                    autoHideBadge: this.hideBadge
                }
            });
        } else {
            // console.log('[Vue Recaptcha] already created');
        }
    },
    mounted() {
        if (this.$recaptcha) {
            // console.log('[Vue Recaptcha] mounted: showing badge');
            this.$recaptchaInstance.showBadge();
        }
    },
    beforeDestroy() {
        if (this.$recaptchaInstance) {
            // console.log('[Vue Recaptcha] beforeDestroy: hiding badge');
            this.$recaptchaInstance.hideBadge();
        }
    },
    watch:   {
        hideBadge: {
            handler(hide) {
                if (!this.$recaptchaInstance) {
                    console.error('[Vue Recaptcha] ' + (hide ? 'hide' : 'show') + ' badge, component not initialized');
                    return;
                }
                // console.log('[Vue Recaptcha] ' + (hide ? 'hide' : 'show') + ' badge');
                if (hide) {
                    this.$recaptchaInstance.hideBadge();
                } else {
                    this.$recaptchaInstance.showBadge();
                }
            }
        }
    },
    methods: {
        /**
         * Return the RECAPTCHA token, or if using a simple challenge, then returns the code provided by the user.
         * @returns {Promise<string|null>}
         */
        getToken() {
            if (this.useTextChallenge) {
                // console.log('[Vue Recaptcha] getToken returning text entered by user:', this.captchaCode);
                return Promise.resolve(this.captchaCode);
            }

            if (this.$recaptcha) {
                return this.$recaptcha(this.context)
            }
            return null;
        },

        reset() {
            this.time = (new Date()).getTime();
            this.captchaCode = null;
            return true;
        },
    },
    data() {
        return {
            id:          false,
            time:        false,
            captchaCode: ''
        }
    }
}
</script>

<style lang="scss">
.grecaptcha-badge {
    z-index: 9999;
}

.captcha-input {
    text-transform: none !important;
}
</style>
