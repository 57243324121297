<template>
    <div class="container-fluid">
        <h1 class="text-center page-title">New Player</h1>
        <div class="bg-white py-5">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-lg-8">

<!--                        <FormulateForm-->
<!--                            name="registrationForm"-->
<!--                            @submit="submit"-->
<!--                            :schema="registrationForm"-->
<!--                            v-model="formData"-->
<!--                            #default="{ isLoading }"-->
<!--                            :values="testData"-->
<!--                            :form-errors="formErrors"-->
<!--                        >-->
<!--                            <recaptcha3 :siteKey="$config.recaptcha.siteKey" ></recaptcha3>-->

<!--                            <button class="btn btn-primary" ref="btnSubmit" :disabled="isLoading">Submit</button>-->
<!--                        </FormulateForm>-->

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import vuexStore from '../store';
import Recaptcha3 from '../components/Recaptcha3';
export default {
    name: "new-player",
    components: {Recaptcha3},
    beforeRouteEnter(to, from, next) {
        if (!vuexStore.get('auth/allowRegister')) {
            next({name: 'home'});
            return;
        }

        next();
    },
    data() {
        return {

            //https://vueformulate.com/guide/validation/#customize-validation-messages
            registrationForm: [
                {
                    name:       'email',
                    label:      'Email (Must be a valid email address)*',
                    type:       'text',
                    validation: 'email',
                    value:      ''
                },
                {
                    name:           'email_confirm',
                    label:          'Verify Email*',
                    type:           'text',
                    validation:     'confirm',
                    validationName: 'Confirmation email',
                    value:          ''
                }, {
                    name:       'password',
                    label:      'Password*',
                    type:       'password',
                    validation: 'required|min:6,length',
                    value:      ''
                }, {
                    name:           'password_confirm',
                    label:          'Re-Type Password*',
                    type:           'password',
                    validation:     'confirm',
                    validationName: 'The password you entered',
                    value:          ''
                },
                {
                    name:           'first_name',
                    label:          'First Name*',
                    type:           'text',
                    validation:     'required',
                    validationName: 'First name',
                    value:          ''
                },
                {
                    name:           'last_name',
                    label:          'Last Name*',
                    type:           'text',
                    validation:     'required',
                    validationName: 'Last name',
                    value:          ''
                },
                {
                    name:           'phone',
                    label:          'Contact Phone Number*',
                    type:           'text',
                    validation:     'min:10,length',
                    validationName: 'Contact phone number with area code',
                    value:          ''
                },
                {
                    name:           'zip',
                    label:          'Zip Code*',
                    type:           'text',
                    validation:     'required',
                    validationName: 'ZIP code',
                    value:          ''
                },
                {
                    name:       'store_id',
                    label:      'Where did you get your game ticket?*',
                    type:       'text',
                    validation: 'required',
                    value:      ''
                },
                {
                    name:           'old_enough',
                    label:          'I am 18 years of age or older*',
                    validationName: 'age requirement',
                    type:           'checkbox',
                    validation:     'accepted',
                    value:          ''
                },
                {
                    name:  'newsletter',
                    label: 'I wish to receive future communication from my selected supermarket.',
                    type:  'checkbox',
                    value: ''
                },
                {
                    name:           'agree_to_terms',
                    label:          'I agree to the Rules & Regulations.*',
                    validationName: 'rules and regulations',
                    type:           'checkbox',
                    validation:     'accepted',
                    value:          ''
                }
            ],
            formErrors: [],
            formData:         null
        }
    },
    computed:{
        registerUrl(){
            // following signup, we get transferred to the /Play/ page, which should then redirect us back to the website
            // inside the mobile app, we will detect that the inAppBrowser was directed to the website, strip the token out of the URL,
            // and use it to authenticate the current session
            return this.$config.hostStoreUrl +  '/card_mod/Card_Login_Stub.las?-token.signup=Reg&fFWD_URL=/Monopoly/Play/';
        }
    },
    mounted(){
        this.beginLoginOrRegister(this.registerUrl);
    },
    methods: {
        beginLoginOrRegister(externalSiteUrl) {
            if (!this.$store.get('global/isApp')){
                top.location.href=externalSiteUrl;
                return;
            }
            let ref = cordova.InAppBrowser.open(
                externalSiteUrl,
                "_blank",
                "location=no,hardwareback=no,zoom=no"
            );

            let                    returnedToSPW   = false;

            ref.addEventListener('exit', function () {

            });

            // Every time a new page loads, I want to know about it
            let handler = (e) => {
                if (returnedToSPW) {
                    return;
                }

                let domain = e.url.match(/^https?:\/\/([^\/?]+)(?:.*)?$/);
                if (domain) {
                    domain = domain[1];
                } else {
                    domain = '';
                }

                if (/topsripitwinit(?:codes)?\.com$|^\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}(?::8081)?$/.test(domain)) {
                    returnedToSPW = true;
                    ref.close();
                    // extract the token from the URL we were returned to,
                    // remember it, and re-initialize the authentication
                    let parsedUrl = new URL(e.url);
                    let eToken = parsedUrl.searchParams.get('sessionid');
                    this.$log.info('Return URL =', parsedUrl.href);
                    this.$log.info('eToken =', eToken );
                    // prefixing the token with * informs the server this is a temporary token
                    this.$store.set('auth/token', '*'+eToken);
                    this.$store.dispatch('auth/refreshUser').then(() => {
                        if (this.$store.get('auth/isLoggedIn')) {
                            this.$router.replace({name: 'enter-code'});
                        } else {
                            // todo something went wrong. we should report this error back to the server
                            this.$router.replace({name: 'returning-player'});
                            // this.$modalService.create('errorLoggingIn');
                        }
                    });
                }
            };

            ref.addEventListener("loadstart", handler);
            // ref.addEventListener("loadstop", handler);
        },

        async submit(data) {
            if (this.$recaptcha) {
                data['captcha'] = await this.$recaptcha('login');
            }
            console.log(data);
            let user = await this.$http.post('user/register', data).catch(({response})=> {
                console.log(response, response.data.error, response.data.error.errorMessage);
                if (response.data.error) {
                    this.formErrors = [response.data.error.errorMessage];
                }
            });
            if (user){
                this.$router.push({name:'enter-code'});
            }
        },
        test() {
            this.formData = this.testData;
        }
    },

};
</script>
