<script>
	export default {
		name: "multi-step",

		props: {
			startingStep: [String, Number]
		},

		data() {
			return {
				currentSlot: this.startingStep || 1,
				history: []
			}
		},

		render(h) {
			let children = [];
			if (this.$slots[this.currentSlot]) {
				let slot = this.$slots[this.currentSlot],
					classes = (slot[0].data.staticClass || '').split(' ');

				slot.forEach((node) => {
					node.key = this.currentSlot;
				});

				children = children.concat(slot);

				let buttons = [];

				if (classes.indexOf('no-back-button') === -1) {
					buttons.push(
						h('button', {
							'class': 'btn btn-primary btn-sm d-block',
							attrs: {
								type: 'button'
							},
							on: {
								click: this.goBack
							}
						}, 'Back')
					);
				}

				if (classes.indexOf('add-form-btn') >= 0) {
					children.push(
						h('button', {
							'class': 'btn btn-primary btn-collapse mt-2',
							attrs: {
								type: 'button'
							},
							on: {
								click: this.goToContactForm
							}
						}, 'Contact Us')
					);
				}

				if (buttons.length) {
					children.push(
						h('div', {
							'class': 'd-flex justify-content-between mt-5'
						}, buttons)
					);
				}
			}
			else {
				children.push(`No step "${this.currentSlot}" found`);
			}

			/*children.unshift(h('div', 'Current: ' + this.currentSlot));
			children.unshift(h('div', 'History: ' + this.history.join(', ')));*/

			return h('div', {
				attrs: {
					'data-step': this.currentSlot
				}
			}, children);
		},

		mounted() {
			this.$watch('currentSlot', (to) => {
				this.$parent.$emit('change', to);
			}, {immediate: true});
		},

		methods: {
			changeStep(newStep) {
				this.history.push(this.currentSlot);
				this.currentSlot = newStep;
			},
			goBack() {
				this.currentSlot = this.history.pop();
			},
			goToContactForm() {
				this.changeStep('contact_form');
			}
		}
	}
</script>
