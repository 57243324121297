<template>
    <div class="main main-agree-to-terms">
        <div class="section-page-primary">

            <div class="container container-wide">
                <!-- WELCOME AND AGREE TO TERMS -->
                <template v-if="pageToShow==='agreeToTermsOrChangeStore'">
                    <h1 class="text-center text-uppercase font-size-h4 mt-100 mb-50 text-light">Welcome</h1>
                    <div class="row">
                        <div class="col-lg-8 col-xl-6 mx-auto">
                            <div class="box bg-white p-4 p-lg-5 rounded">

                                <div class="page-header">
                                    <h2 class="pb-3 lead text-center">Before we begin...</h2>
                                </div>

                                <!-- AGREE TO TERMS -->
                                <div v-if="mustAgreeToTerms" class="box bg-white form-group mx-n3 px-3">
                                    <div class="mb-1">Please confirm:</div>

                                    <checkbox-custom v-model="form.meets_age_requirement" id="meets_age_requirement_4u" required>I am 18 years of age or older.*</checkbox-custom>
                                    <div class="form-error" v-if="errors.meets_age_requirement">{{ errors.meets_age_requirement }}</div>

                                    <checkbox-custom v-model="form.agrees_to_terms" id="agrees_to_terms_4u" class="mt-3" required>I agree to the
                                        <a href="/rules" target="_blank" @click.prevent="showRules($event)">Rules &amp; Regulations</a>.*
                                    </checkbox-custom>
                                    <div class="form-error" v-if="errors.agrees_to_terms">{{ errors.agrees_to_terms }}</div>
                                </div>

                                <!-- CHOOSE STORE -->
                                <div v-if="mustProvideNewStore" class="box box-blue box-thin mt-4">
                                    <div>Please select your store:
                                        <btn-tooltip tip="Please select the store where you shop. Some prizes are regional so we need to know which store you frequent."/>
                                    </div>
                                    <choose-store v-model="form.store_id" @ready="storeChooserReady"></choose-store>
                                </div>

                                <div class="mt-4" v-if="mustProvidePhoneNumber">
                                    <p>A ten-digit phone number is required to contact you if you win the grand prize.</p>
                                    <div>Contact phone number:</div>
                                    <input type="tel" class="form-control" v-model="form.phone"/>
                                    <div class="form-error" v-if="errors.phone">{{ errors.phone }}</div>
                                </div>


                                <div class="text-center text-danger mt-4" v-if="errors.form" v-html="errors.form"></div>
                                <!-- SUBMIT -->
                                <div class="row mt-4">
                                    <div class="col">
                                        <div v-if="mustAgreeToTerms" class="font-size-small agree-to-terms-legal-footer mt-sm-4">* Required</div>
                                        <button v-if="showContinueBtn"
                                                slot="footer"
                                                type="button"
                                                class="btn btn-primary mx-auto d-flex mt-3"
                                                ref="letMePlayBtn"
                                                @click="confirm"
                                                :disabled="!ready"
                                                data-style="zoom-out"
                                                v-ada>Let's Play!
                                        </button>
                                    </div>
                                </div>
                            </div><!-- /.box -->
                        </div><!-- /.col -->
                    </div><!-- /.row -->
                </template>

                <!-- STANDARD ACCOUNT PROFILE PAGE -->
                <template v-else-if="pageToShow==='profile'">
                    <div class="row">
                        <div class="col-lg-8 col-xl-6 mx-auto">
                            <div class="page-header ticket">
                                <lang id="agree-to-terms-confirm-eligibility">
                                    <h1 class="page-title text-center text-uppercase font-size-h3 text-light">Account</h1>
                                </lang>
                            </div>
                            <div class="box bg-white p-4 p-lg-5 box-profile rounded">
                                <!-- USER PROFILE -->
                                <p class="text-center">Please review your contact information below. It must be correct for us to reach you should you win a prize.</p>

                                <div v-if="isTester" class="card bg-warning text-dark text-center mb-3">
                                    <strong>You are logged into a TEST account</strong>
                                </div>

                                <div class="row mb-2 pt-2 border-top" v-for="(value, field) in user.profile" :key="field">
                                    <div class="col-sm-4 field-label" v-html="field"></div>
                                    <div class="col-sm-8 field-value" v-html="value"></div>
                                </div>

                                <!-- BUTTONS -->
                                <div class="mt-4">
                                    <button @click="logoutAndRedirectTo(editProfileUrl)"
                                            v-if="editProfileUrl"
                                            class="btn btn-primary btn-block"
                                            ref="editProfileBtn"
                                            data-style="zoom-out"
                                            v-ada>Edit Contact Information
                                    </button>

                                    <button v-if="showContinueBtn"
                                            slot="footer"
                                            type="button"
                                            class="btn btn-primary btn-block"
                                            ref="letMePlayBtn"
                                            @click="confirm"
                                            :disabled="!ready"
                                            data-style="zoom-out"
                                            v-ada>Information Correct. Continue.
                                    </button>

                                </div>

                                <div v-if="mustAgreeToTerms" id="agree-to-terms-legal-footer" class="font-size-small agree-to-terms-legal-footer">* Required</div>
                            </div>
                        </div>
                    </div>

                </template>

            </div><!-- /.container -->
        </div>

    </div>
</template>
<script>
import uniq from 'lodash/uniq';
import map from 'lodash/map';
import toArray from 'lodash/toArray';
import filter from 'lodash/filter';
import {get} from "vuex-pathify";
import tracker from "../components/trackers/General";
import chooseStore from '../components/ChooseStore';
import api from '../utils/axios'
import checkboxCustom from '../components/checkbox-custom';
import btnTooltip from '../components/btn-tooltip';

export default {
    name:       'account',
    components: {
        tracker,
        chooseStore,
        checkboxCustom,
        btnTooltip
    },
    data() {
        return {
            busy:           false,
            captcha:        false,
            storeLoadError: false,
            banner:         this.$store.get('auth/userData').banner,
            form:           {
                store_id:              null,
                meets_age_requirement: false,
                agrees_to_terms:       false,
                confirm_information:   true,
                phone:                 ''
                // must_verify_profile:   0
            },
            errors:         {
                meets_age_requirement: false,
                agrees_to_terms:       false,
                captcha:               false,
                phone:                 false,
                form:                  false
            }
        }
    },

    // created() {
    //     this.loadStores();
    // },
    computed: {
        pageToShow() {
            if (this.userErrors) {
                return 'agreeToTermsOrChangeStore';
            }
            if (this.user) {
                return 'profile';
            }
            return null;
        },
        show_confirm_info_section() {
            return false;
            return this.mustAgreeToTerms;
        },
        showContinueBtn() {
            // seems we always need to show the Continue button
            return true;
            // return !(this.userErrors);
        },
        pageTitle() {
            if (this.mustAgreeToTerms) {
                return 'Confirm Eligibility';
            }
            return 'Account';
        },
        // j4uAppDownloadLinks() {
        // 	return this.$store.get('auth/userData').j4uAppLinks;
        // },
        // editProfileUrl() {
        // 	return this.$store.get('auth/userData').edit_profile_url;
        // },
        j4uAppDownloadLinks: get('auth/userData@j4uAppLinks'),
        editProfileUrl:      get('auth/userData@edit_profile_url'),
        isTester:            get('auth/user@isTester'),
        user:                get("auth/user"),
        userErrors:          get('auth/user@errors'),
        // mustVerifyProfile:   get('auth/user@must_verify_profile'),
        mustAgreeToTerms: get('auth/mustAgreeToJ4UTerms'),
        mustProvideNewStore() {
            // for tops, we do not care about stores
            return false;

            let data = this.$store.get('auth/userData');
            // let state = this.user.state;
            let storeNumber = parseInt(data.store_number);
            let mustChooseStore = !(storeNumber > 0);
            return mustChooseStore;
        },
        modal() {
            return this.$store.get('auth/userData').agree_to_terms_modal;
        },
        provider() {
            let data = this.$store.get('auth/userData');
            return data.agree_to_terms_modal.provider;
        },
        website() {
            return 'www.website.com'
        },
        ready() {
            let ready = true;
            if (this.mustAgreeToTerms) {
                ready = ready && this.form.meets_age_requirement && this.form.agrees_to_terms;
            }
            if (this.mustProvideNewStore) {
                ready = ready && this.form.store_id;
            }
            if (this.show_confirm_info_section) {
                ready = ready && this.form.confirm_information;
            }
            return ready;

        },
        // todo
        storeLogoIndex() {
            let x = this.$store.get('auth/userData').j4uAppMessageStoreIndex
            return x;
        },
        rewardsProgramName() {
            let x = this.$store.get('auth/userData').rewardsProgramName;
            x = x && x.toLowerCase();
            return x || 'just 4 u';
        },
        rewardsProgramIndex() {
            let programs = ['just 4 u', 'mymixx', 'rewards!'];
            let n = programs.indexOf(this.rewardsProgramName);
            if (n < 0) {
                n = 0;
            }
            return n;
        },
        isDev() {
            return this.$config.env === 'local'
        },
        logoutUrl() {
            return this.$http.defaults.baseURL + "/user/logout?all=true";
        },

        mustProvidePhoneNumber() {
            let phone = this.$store.get('auth/currentUserPhone');
            return !phone;
        }
    },
    methods:  {
        storeChooserReady() {
            // already a computed function named ready
        },
        logoutAndRedirectTo(url) {
            // calling this removes the user, which breaks this page...
            // this.$store.dispatch('auth/logoutUser')
            this.busyButton(this.$refs.editProfileBtn, true, 'account');
            this.$http.get('/user/logout').then(() => {
                // logout successful, redirect
                top.location.href = url;
            });
        },
        showRules(e) {
            this.$modalService.create('Rules').setReturnElement(e.target);
        },
        confirm() {
            // nothing to do except go to the Enter Code page
            if (!this.mustAgreeToTerms && !this.mustProvideNewStore) {
                this.$router.replace({name: 'enter-code'});
                return;
            }
            this.errors.form = null;
            this.errors.phone = null;
            // if (this.mustProvidePhoneNumber && this.form.phone.length < 10) {
            //     this.errors.phone = 'Please enter a 10-digit contact phone number';
            // }
            if (this.mustAgreeToTerms) {
                if (!this.form.meets_age_requirement) {
                    this.errors.meets_age_requirement = 'You must be 18 or older to play.';
                } else {
                    this.errors.meets_age_requirement = null;
                }
                if (!this.form.agrees_to_terms) {
                    this.errors.agrees_to_terms = 'You must agree to the rules and regulations.';
                } else {
                    this.errors.agrees_to_terms = null;
                }

                this.errors.captcha = this.$refs.captcha && !this.$refs.captcha.getResponse();


                let hasErrors = Object.keys(this.errors).some((field) => this.errors[field]);

                if (hasErrors) {
                    return;
                }
            } else {
            }
            console.log('btn=',this.$refs.letMePlayBtn);
            this.busyButton(this.$refs.letMePlayBtn, true, 'account');
            this.$http.post('user/update', this.form).then((response) => {
                this.$router.replace({name: 'enter-code'});
            }).catch(xhr => {
                this.errors.form = "Your request could not be processed at this time. Please try again later.";
                this.busyButton(this.$refs.letMePlayBtn, false, 'account');
            });
        }
    },
}
</script>

<style lang="scss" scoped>

.agree-to-terms-legal-footer {
    @media(min-width: $sm) {
        position: absolute;
    }
}

.box-thin {
    border-radius: 10px;
    padding: 20px;
    border-width: 2px;
}

.box-profile {
    line-height: 1.25;
}

.field-label {
    font-weight: bold;

    &:after {
        content: ':';
        @media(min-width: $sm) {
            content: '';
        }
    }
}

.field-value {
}

.field {
    min-width: 6rem;
    white-space: normal;
    word-break: normal;
}

.nowrap {
    white-space: nowrap;
}

.j4u-app-promo {
    background-color: #1e549e;
    font-family: $fontSpecial;
}

.rewards-logo {
    max-width: 150px;
    height: 50px;
}

.store {
    display: block;
    background-color: white;
    background-size: cover;
    transition: transform 250ms;
    width: 100%;
    border: 0;
    padding: 50%;
}

$stores: 18;
@for $i from 1 through $stores {
    .store-#{$i} {
        background-position-y: (100 / ($stores - 1)) * $i + 0%;
    }
}

.store-reward-engine-image {
    //background-image: url("../assets/images/img-map-reward-programs.png");
    background-size: cover;
    background-color: $light;
}

.store-reward-engine-image-2 {
    background-position-y: 88%;
}

.store-reward-engine-image-0 {
    background-position-y: 13%;
}

.user-info {
    background-color: $blue;
    border: 10px solid $light;
    padding: 50px;
}

caption {
    caption-side: top;
    font-size: $size30;
    font-family: $fontSpecial;
    font-weight: 700;
}

th {
    font-size: $size30;
    font-family: $fontSpecial;
    font-weight: 700;

    &.font-size-normal {
        @include responsive-font-size(18px);
    }
}

table {
    color: $light;
}

thead {
    th {
        border-top: none;
    }
}

.tokens-overview {
}

.download-the-app {
    @include rfs(22px);
    text-align: center;
}
</style>
