<template>
	<modal classes="modal-stars leaving-modal" size="lg">
		<h1 slot="header" class="sr-only" slot-scope="{id}" :id="id">Login or Sign up</h1>
		<div slot="body" class="description text-center">
			<div class="d-flex justify-content-center spacing image-blocks mx-auto">
				<div class="store-banner-image mb-1 w-100 square" :class="`store-banner-image-${bannerIndex}`">
					<span class="sr-only">{{banner}}</span>
				</div>
				<div class="store-reward-engine-image mb-1 w-100 square" :class="`store-reward-engine-image-${rewardsProgramIndex}`" :title="rewardsProgramName">
					<span class="sr-only">{{rewardsProgramName}}</span>
				</div>
			</div>
			<lang id="store-check-message">
				<h2 class="font-size-h5 text-center text-uppercase" v-ada="'aria-label'">Do you have <span class="store-banner">{{ aBannerName }}</span>
					<span class="store-reward-engine">{{ rewardsProgramName }}</span> account?</h2>
			</lang>
			<recaptcha v-if="$config.useCaptcha" ref="captcha" class="d-inline-block mx-auto" @validated="captchaReady=true"></recaptcha>
		</div>
		<div slot="footer" class="d-flex flex-wrap w-100">
			 <button type="button" class="btn ticket d-block flex-grow-1 mb-1" @click="proceed('login')" :disabled="!captchaReady || busy" v-ada>Yes, log me in!</button>
			 <button type="button" class="btn ticket d-block flex-grow-1 mb-1" @click="proceed('register')" :disabled="!captchaReady || busy" v-ada>No, sign me up!</button>
		</div>
	</modal>
</template>
<style lang="scss" scoped>
	.image-blocks {
		max-width: 320px;
	}

	.store-reward-engine-image {
		background-size: cover;
		background-color: $light;
	}

	.store-banner-image {
		background-size: cover;
		background-color: $light;
	}

	$stores: 18;
	@for $i from 1 through $stores {
		.store-banner-image-#{$i} {
			background-position-y: (100 / ($stores - 1)) * $i +0%;
		}
	}

	$programs: 3;
	@for $i from 1 through $programs {
		.store-reward-engine-image-#{$i} {
			background-position-y: (100 / ($programs - 1)) * $i +0%;
		}
	}
</style>
<script>
	import Recaptcha from '../../components/Recaptcha';
	import findKey from 'lodash/findKey';

	export default {
		name:       "accountCheck",
		components: {Recaptcha},
		data() {
			return {
				busy:         false,
				captchaReady: !this.$config.useCaptcha
			}
		},
		props:      {
			modal:       {
				type:     Object,
				required: true
			},
			banner:      String,
			bannerIndex: [String, Number]
		},

		computed: {
			aBannerName() {
				let prefix = 'a ';
				let firstLetter = this.banner.substr(0, 1).toLowerCase(),
				    vowels      = ['a', 'e', 'i', 'o'];
				if (vowels.indexOf(firstLetter) >= 0) {
					prefix = 'an ';
				}
				return `${prefix} ${this.banner}`;
			},
			bannerName() {
				return this.banner
			},
			rewardsProgramName() {
				let banner = this.banner;
				let sessionData = this.$store.get('auth/sessionData');
				let loyaltyPrograms = sessionData.loyaltyPrograms;
				// note: if loyaltyPrograms is undefined, then the server never responded to the GET /user request
				if (!loyaltyPrograms) {
					return null;
				}
				let programName = findKey(loyaltyPrograms, (banners) => banners.includes(banner))
				if (programName.toLowerCase() === 'just 4 u') {
					return 'Just For U';
				}
				return programName;
			},
			rewardsProgramIndex() {
				let x = this.rewardsProgramName;
				let programs = ['just 4 u', 'mymixx', 'rewards!'];
				return programs.indexOf(x);
			},
		},

		methods: {
			/**
			 *
			 * @param eventName login | register
			 */
			proceed(eventName) {
				// uncomment to make captcha check more robust
				// this.captchaReady =  !this.$config.useCaptcha||this.$refs.captcha.getResponse()

				if (this.captchaReady) {
					this.busy = true;
					this.modal.trigger(eventName, this.banner);
				}
			}
		}
	};
</script>
