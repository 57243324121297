<template>
	<div class="main main-terms">
		<div class="container">
			<div class="page-header ticket">
				<lang id="terms-and-conditions-title">
					<h1
						class="page-title text-center text-uppercase font-size-h3"
					>Website Terms of Use</h1>
				</lang>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "terms",
		data() {
			return {};
		},
		mounted() {
			$(".main-privacy a").attr({ target: "_blank", rel: "noopener" });
		}
	};
</script>

<style lang="scss" scoped>
	// ol li {display:block;}
	ol > li:first-child {
		counter-reset: item;
	}
	ol ol {
		margin-bottom: 30px;
	}
	ol ol li {
		display: block;
	}
	ol > li {
		counter-increment: item;
		position: relative;
	}
	ol ol > li:before {
		content: counters(item, ".");
		position: absolute;
		margin-right: 100%;
		right: 10px;
		white-space: nowrap;
	}
</style>
