<template>
	<div class="json-visualizer">
		<div class="top-section">
			<div class="path">
				<button :disabled="!path.length" type="button" @click="back" v-ada>Back</button> {{prettyPath}}
			</div>
		</div>

		<div v-if="type == 'string' || type == 'number' || type == 'function'" class="input-group">
			<div class="form-control">{{current}}</div>
		</div>

		<div v-if="type == 'boolean'" class="input-group">
			<div class="form-control">{{current ? 'TRUE' : 'FALSE'}}</div>
		</div>

		<template v-if="type == 'object'">
			<div v-for="(item, ndx) in current" class="input-group">
				<label class="input-group-addon">{{ndx}}:</label>
				<div class="form-control" @click="go(ndx)">
					{{toString(item)}}
				</div>
			</div>
		</template>
	</div>
</template>

<style scoped>
	.json-visualizer .form-control {
		word-break: break-word;
		cursor: pointer;
	}
</style>

<script>
	export default {
		name: 'json-visualizer',

		props: {
			val: {
				type: [Object, Array, String, Boolean, Number]
			},

			root: {
				type: String,
				default: ''
			}
		},

		data() {
			return {
				path: []
			}
		},

		computed: {
			current() {
				let item = this.val;

				for (let i = 0; i < this.path.length; i++) {
					if (typeof item[this.path[i]] === 'undefined') {
						this.path = this.path.slice(0, i);
						return item;
					}

					item = item[this.path[i]];
				}

				return item;
			},

			type() {
				return typeof this.current;
			},

			prettyPath() {
				let path = this.root;
				if (this.path.length) {
					path += '.';
				}

				return path + this.path.join('.');
			}
		},

		methods: {
			back() {
				if (this.path.length) {
					this.path.pop();
				}
			},

			go(ndx) {
				this.path.push(ndx);
			},

			toString(item) {
				if (typeof item === 'object') {
					let res = JSON.stringify(item);
					if (res.length > 200) {
						res = res.substr(0, 200) + '...';
					}

					return res;
				}

				return item;
			}
		}
	}
</script>