<template>
	<modal v-bind="modalOpts">
        <div slot="header"></div>
		<div slot="body" class="text-center">
            <img class="img-fluid mb-4 w-75" src="@/assets/images/EnterCode-winner.png" alt="A winning code was entered" v-if="modal.opts.isWinner">
            <img class="img-fluid mb-4 w-75" src="@/assets/images/InvalidCode.png" alt="An incorrect code was entered" v-else>

            <h2 v-html="title" v-if="title"></h2>
            <div v-html="message"></div>
        </div>
		<div slot="footer" class="btn-block">
			<button type="button" class="btn btn-primary btn-block btn-rounded purple-gradient-vertical mb-1" @click="closeModal" v-ada ref="btnOk">Enter Another Code</button>
			<router-link :to="{name: 'code-history'}" class="btn btn-primary btn-block btn-rounded purple-gradient-vertical mb-1">Visit Code History</router-link>
		</div>
	</modal>
</template>

<script>
    export default {
        name:     'genericCodeEntryMessage',
        props:    {
			modal: {
				type: Object,
				required: true
			},
            title:   String,
            message: String,
            theme:   String,
            opts:    Object
        },
        computed: {
            modalOpts() {
                let defaults = {
                    class: ['modal-lg']
                };

                return $.extend(defaults, this.opts || {});
            }
        },
		methods:{
            closeModal() {
                if (this.$route.name !=='enter-code'){
                    this.$router.push({name:'enter-code'});
                    return;
                }
				this.modal.trigger('close');
            }
		},
		mounted(){
        	// unsure this is the correct approach for preventing certain modal elements from receiving focus
        	$(this.$el).find('.modal-close').focus();
		}
    }
</script>

<style lang="scss">
    main {
        z-index: 11;
    }
</style>
