<template>
    <div class="container-fluid">
        <h1 class="text-center page-title text-light">Code History</h1>

		<div class="pt-4 pt-xl-5">
            <div v-if="hasNoData()" class="text-center container bg-white p-4 p-xl-5 rounded">
                <lang id="code-history-no-data">Please wait...</lang>
            </div>
            <div v-if="hasNoCodes()" class="text-center font-weight-bold container bg-white p-4 p-xl-5 rounded">
                <lang id="code-history-no-codes">You have not entered any codes.</lang>
            </div>

            <div v-if="hasCodes()" class="container bg-white p-4 p-xl-5 rounded">
                <!-- WINNING CODES -->
                <form target="_blank" method="post" id="history-form" :action="submitUrl">
                    <input type="hidden" name="session_id" :value="sessionId">
                    <input type="hidden" name="_" :value="codeIdToPrint">
                    <input type="hidden" name="s" :value="specialPrizeIdToPrint">

                    <div class="bg-white p-3 p-lg-5 mb-3 text-center">
                        <h2 style="font-size: 3.5rem">
                            {{ codes.winning.length + codes.losing.length }}
                            <span class="codes-submitted d-block mt-2" style="font-size:1rem;">Codes submitted</span><span class="sr-only">.</span>
                        </h2>
                    </div>

                    <div class="bg-white p-3 p-lg-5">
                        <!-- region SPECIAL PRIZES -->
                        <table class="table code-table code-table-winning" v-if="codes && codes.special">
                            <caption class="lead">Verification Prize Form{{ Object.keys(codes.special).length > 1 ? 's' : '' }}</caption>
                            <thead>
                            <tr>
                                <th width="150px" class="print-column text-center">View<span class="sr-only">.</span></th>
                                <th>Prize Description<span class="sr-only">.</span></th>
                                <th width="290px">Date<span class="sr-only">.</span></th>
                            </tr>
                            </thead>
                            <tr v-for="item in codes.special" :key="item.id" :data-id="item.id">
                                <td class="print-column text-md-center">
                                    <template v-if="item.form">
                                        <template v-if="!$store.get('global/isApp')">
                                            <button class="btn btn-primary btn-spend btn-sm" @click="printSpecialPrizeForm(item.id)">Print</button>
                                        </template>
                                        <template v-else>
                                            <span class="warning bg-danger p-2">Use PC to print</span>
                                        </template>
                                    </template>
                                </td>
                                <!-- DESCRIPTION -->
                                <td v-html="item.description"></td>

                                <!-- DATE -->
                                <td nowrap class="font-size-small">
                                    <span class="sr-only d-none d-md-inline">won on</span><strong class="d-md-none">Date Won:</strong> {{ item.created_at }}
                                </td>

                            </tr>
                        </table>
                        <!-- endregion -->

                        <!-- region Winning Codes Entered -->
                        <table class="table code-table code-table-winning">
                            <thead>
                            <tr>
                                <th width="150px" class="print-column text-center">Redeem<span class="sr-only">.</span></th>
                                <th>Prize Description<span class="sr-only">.</span></th>
                                <th width="95px">Date<span class="sr-only">.</span></th>
                                <th width="195px">Code<span class="sr-only">.</span></th>
                            </tr>
                            </thead>
                            <tbody class="winning">
                            <tr v-for="item in codes.winning" :key="item.id" :data-id="item.id">
                                <!-- CHECKBOX -->
                                <td class="print-column text-md-center">
                                    <template v-if="item.form">
                                        <button class="btn btn-primary btn-spend btn-sm" @click="printPrizeForm(item.id)">Print</button>
                                    </template>
                                    <template v-else-if="item.type && item.type==='MagicToken' && item.redeemable">
                                        <router-link :to="{name:'magic-tokens'}" class="btn btn-primary btn-sm btn-spend">Spend</router-link>
                                    </template>
                                    <template v-else-if="item.redeem_url">
                                        <!-- For the redeem url -->
                                        <a :href="redeemUrl(item.redeem_url).uri"
                                           class="btn btn-sm btn-primary"
                                           :target="redeemUrl(item.redeem_url).openInNewTab ? '_blank' : '_self'"
                                           rel="noopener">Redeem</a>
                                    </template>
                                    <template v-else-if="(typeof item.c2c!=='undefined')" class="d-none d-md-inline-block">
                                        <fa-icon v-if="item.c2c" icon="faCheck" pack="solid" size="2x" classes=""></fa-icon>
                                        <div v-else data-style="zoom-out" class="btn btn-sm btn-clip btn-primary" @click="clip(item, $event)">{{ item.clip_label || 'Activate' }}</div>
                                    </template>
                                </td>

                                <!-- DESCRIPTION -->
                                <td v-if="item.description" v-html="item.description"></td>
                                <td v-else><strong class="d-md-none">Prize Description:</strong> <span v-html="item.prize"></span></td>

                                <!-- DATE -->
                                <td nowrap class="font-size-small"><strong class="d-md-none">Date:</strong> {{ item.created_at }}</td>
                                <!-- CODE -->
                                <td nowrap class="entered-code font-size-small" v-if="item.code">
                                    <span class="sr-only d-none d-md-inline">Code is</span><strong class="d-md-none">Code:</strong> {{ item.code }}
                                </td>
                                <td nowrap class="entered-code font-size-small" v-else></td>
                            </tr>
                            </tbody>
                        </table>
                        <!-- endregion -->

                        <!--region PRINT PRIZE FORMS BUTTON -->
                        <div v-if="showPrintButton" class="mt-lg-5 text-center" tabindex="0">
                            <!--								<button type="submit" id="print-button" name="submitbtn" class="btn btn-primary mr-3" :disabled="!checkedBoxes.length" v-ada>-->
                            <!--									<lang id="code-history-print-forms">Print Selected Form</lang><span class="sr-only">.</span>-->
                            <!--								</button>-->
                            <lang id="code-history-download-adobe-reader" class="font-size-small py-lg-3">
                                In order to view and/or print the prize forms you will need to download <a href="https://get.adobe.com/reader/" target="_blank">Adobe Reader</a>.
                            </lang>
                        </div>
                        <!-- endregion -->

                    </div>

                    <!-- NON-WINNING CODES -->
                    <div v-if="codes && codes.losing && codes.losing.length" class="code-table-losing-container bg-white p-3 p-md-5 mt-3">
                        <table class="table table-sm code-table">
                            <thead>
                            <tr>
                                <th>Description</th>
                                <th width="95px">Date</th>
                                <th width="195px">Code</th>
                            </tr>
                            </thead>
                            <tbody class="losing">
                            <tr v-for="item in codes.losing" :key="item.id">
                                <!-- DESCRIPTION -->
                                <td class="font-size-small pl-md-1">Not a winner</td>
                                <!-- DATE -->
                                <td nowrap class="font-size-small">
                                    <span class="sr-only d-none d-md-inline">entered on</span><strong class="d-md-none">Date Entered:</strong> {{ item.created_at }}
                                </td>
                                <!-- CODE -->
                                <td nowrap class="entered-code font-size-small">
                                    <span class="sr-only d-none d-md-inline">Code is</span><strong class="d-md-none">Code:</strong> {{ item.code }}
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>


                </form>
            </div>
        </div>

        <!-- <page-sub-section :cols="['winners', 'faq']"></page-sub-section> -->
    </div>
</template>

<script>
import {get} from "vuex-pathify";
import vuexStore from '../store'

export default {
    name: "history",

    data() {
        return {
            codes:                 null,
            submitUrl:             `${this.$config.serverUrl}/app/game/getPrizeForm`,
            checkedBoxes:          [],
            codeIdToPrint:         null,
            specialPrizeIdToPrint: null
        };
    },
    computed: {
        sessionId: get("auth/session_id"),
        showPrintButton() {
            return (
                !this.$store.get("global/isApp") &&
                this.codes &&
                this.codes.winning &&
                this.codes.winning.some(code => code.form)
            );
        }
    },
    created() {

        this.$http.get("/game/codes").then(
            response => {
                this.codes = response.data;
            },
            () => {
            }
        );
    },

    mounted() {
        this.watchConnectivity(true);
    },
    beforeDestroy() {
        this.watchConnectivity(false);
    },

    methods: {
        printSpecialPrizeForm(id) {
            this.specialPrizeIdToPrint = id;
        },
        printPrizeForm(id) {
            this.codeIdToPrint = id;
        },
        showCoupon(item) {
            this.$modalService.create('couponDetails', {info: item})
        },
        // selectItem(item) {
        //   if (item.form) {
        //     let id = item.id;
        //     let existing = this.checkedBoxes.indexOf(id);
        //     if (existing >= 0) {
        //       this.checkedBoxes.splice(existing, 1);
        //     } else {
        //       this.checkedBoxes.push(id);
        //     }
        //   }
        // },
        //
        prizesExist() {
            let prizeCount = 0;
            try {
                $.each(this.codes, (n, code) => {
                    if (this.hasPrizeForm(code)) {
                        prizeCount++;
                    }
                });
            } catch (ignore) {
            }
            return prizeCount > 0;
        },
        select(e) {
            let $el       = $(e.target),
                isChecked = $el.is(":checked");
        },

        hasCodes() {
            return (
                this.codes &&
                this.codes.winning &&
                this.codes.losing &&
                (this.codes.winning.length || this.codes.losing.length)
            );
        },
        hasNoCodes() {
            return (
                this.codes &&
                this.codes.winning &&
                this.codes.losing &&
                this.codes.winning.length === 0 &&
                this.codes.losing.length === 0
            );
        },
        hasNoData() {
            return this.codes === null;
        },
        submit() {
        },
        redeemUrl(uri) {
            let result;

            if (result = uri.match(/^\/?(#\/.+)$/)) {
                result = {
                    uri:        result[1],
                    isLocalUri: true
                };
            } else {
                result = {uri};
            }

            result.openInNewTab = !result.isLocalUri;

            return result;
        },
        clip(item, event) {
            if (item.busy) {
                return
            }
            item.busy = true;
            this.busyButton(event.target, true, item.id)
            this.$http.get(`/game/clipCoupon/${item.id}`).then(response => {
                item.c2c = 'clipped';
                this.busyButton(event.target, false, item.id);
            }, error => {
                this.$modalService.create('generic', {
                    title:   'Error',
                    message: 'Sorry, the request could not be completed at this time.'
                });
                this.busyButton(event.target, false, item.id);
                item.busy = false;
            })
        }
    },
    beforeRouteEnter(to, from, next) {
        if (!vuexStore.get('auth/allowCodeHistory')) {
            next({name: 'home'});
            return;
        }

        next();
    },
    watch: {
        checkedBoxes(to) {
            let message = `Print Selected Forms button ${to.length ? 'enabled' : 'disabled'}`;
            this.alertADA(message);
        }
    }
};
</script>

<style lang="scss" scoped>

caption {
    caption-side: top;
}

@media (max-width: $md - 1) {
    .table {
        @include responsive-font-size(14px);
        color: $dark;

        margin-bottom: 0;

        thead {
            display: none;
        }

        tr {
            position: relative;

            td {
                display: block;
                border: none;
                padding: 0;

                &:nth-child(1) {
                    //padding-top: 10px;
                    //padding-bottom: 10px;
                    .btn {
                        margin-bottom: 6px;
                    }
                }

                &:nth-child(2) {
                    line-height: 1.25;
                }

                &:last-child {
                    padding-bottom: 16px;
                    margin-bottom: 16px;
                    border-bottom: 2px solid $blue;
                }
            }
        }
    }
}

.code-table-losing-container {
    max-height: 300px;
    overflow: auto;
}

.check-box {
    margin-top: 5px;
}

.card-event {
    line-height: 1.2;
}

.table-sm {
    td {
        padding: 5px;
    }
}

table {
    @include responsive-font-size(16px);

    thead {
        border: 0;

        th {
            position: sticky;
            top: 0;
            border: 0;
            font-weight: bold;
            text-transform: uppercase;
            border-bottom: none;
        }
    }

    td {
        padding: 20px 10px;
        vertical-align: middle;
        border-color: #ffffff;

        &:first-child {
            padding: 0;

            a {
                margin-top: 1px;
            }
        }
    }

    .selectable {
        cursor: pointer;
    }

    .selected {
        color: $red;
    }
}

.text-underline {
    text-decoration: underline;
}

.btn-spend, .btn-clip {
    min-width: 7em;
}

.codes-submitted {
    color: $purple;
    .employee & {
        color: $employeePrimary;
    }
}
</style>
