<template>
	<div class="main main-login">
		<div class="container">
			<div class="page-header">
				<h1 class="page-title text-center text-uppercase font-size-h3">API Request Log</h1>
			</div>
		</div>
		<div class="container-fluid">
			<div class="table-responsive-sm">
				<table class="table table-sm">
					<thead class="thead-dark">
					<tr>
						<th>Date</th>
						<th>Request URL</th>
						<th>Remote Client</th>
						<th>Extra</th>
					</tr>
					</thead>
					<tbody>
					<template v-for="item in logs">
						<tr>
							<td nowrap>{{item.created_at | formatDate}}</td>
							<td nowrap>{{item.request_method}} {{item.request_url}}</td>
							<td>{{item.client_address}}</td>
							<td><span v-if="item.request.signatureMismatch" class="badge badge-warning badge-pill">Incorrect Signature</span></td>
						</tr>
						<tr>
							<td colspan="2">
								<json-visualizer :val="{'Request Headers': item.request.headers,'Request Body': item.requestBodyJson, 'Extra': item.request.extra}" root="item"></json-visualizer>
							</td>
							<td colspan="2">
								<json-visualizer :val="{'Response Headers': item.response.headers, 'Response Body': item.responseBodyJson}" root="item"></json-visualizer>
							</td>
						</tr>
					</template>
					</tbody>
				</table>
			</div>
		</div>
	</div>
</template>
<style lang="scss">
	.table-sm {
		font-size: 12px;
		.input-group .form-control {
			font-size: inherit
		}
	}
	.input-group-addon {
		width: 160px;
		text-align: right;
		justify-content: flex-end;
	}
</style>
<script>

	import {setStorage} from '@/utils/storage'
	import ladda from 'ladda';
	import jsonVisualizer from '@/components/jsonVisualizer';

	export default {
		name: 'request-log',
		components: {jsonVisualizer},
		computed: {},
		data() {
			return {
				logs: ''
			}
		},

		mounted() {
			this.update()
		},
		methods: {
			update() {
				// api.get('/request-log').then((response) => {
				// 	this.logs = response.data;
				// 	setTimeout(this.update, 1500);
				// });
			}
		}
	}
</script>

