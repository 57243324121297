/**
 * runtime or static configuration values, depends on how the app was built
 * @returns {{useTrackers: boolean, serverUrl: (string|string|*), NODE_ENV, useCaptcha: boolean}}
 */
function configValues() {

	let config = {
		NODE_ENV:            process.env.NODE_ENV,
		serverUrl:           process.env.VUE_APP_BASE_URL,
		hostRegistrationUrl: '',
		useCaptcha:          false,
		useTrackers:         !/localhost|\.local$/.test(location.hostname)
	}

	if (/^www\.topsripitwinit(codes)?\.com$/.test(location.hostname)) {
		config = {
			NODE_ENV:            'production',
			serverUrl:           'https://www.topsripitwinit.com',
			hostStoreUrl:        'https://www.topsmarkets.com',
			hostRegistrationUrl: 'https://www3.topsmarkets.com/consumers/retailers/767/accounts/new?send_credentials=true&skip_verification=t&url=https://www.topsripitwinit.com/app/user/login',
			useCaptcha:          true,
			useTrackers:         true
		}
	} else if (/^qa\./.test(location.hostname)) {
		config = {
			NODE_ENV:     'staging',
			serverUrl:    'https://qa.topsripitwinit.com',
			hostStoreUrl: 'https://tops2.lasso1.webstophq.com',
			// hostRegistrationUrl: 'https://admin-tops-qa.grocerywebsite.com/consumers/retailers/767/accounts/confirm_identity',
			hostRegistrationUrl: 'https://admin-tops-qa.grocerywebsite.com/consumers/retailers/767/accounts/new?send_credentials=true&skip_verification=t&url=https://qa.topsripitwinit.com/app/user/login',
			useCaptcha:          true,
			useTrackers:         true
		}
	} else if (/localhost|\.local$/.test(location.hostname)) {
		let host = location.hostname.replace('codes', '');
		config = {
			NODE_ENV: 'local',
			// serverUrl:    `${location.protocol}//${host}`,
			serverUrl: 'http://www.topsripitwinit.local',
			// serverUrl:    'https://qa.topsripitwinit.com',
			// hostRegistrationUrl: 'https://admin-tops-qa.grocerywebsite.com/consumers/retailers/767/accounts/confirm_identity?send_credentials=true&url=http://localhost:8080/#/',
			hostRegistrationUrl: 'https://admin-tops-qa.grocerywebsite.com/consumers/retailers/767/accounts/new?send_credentials=true&skip_verification=t&url=http://www.topsripitwinit.local/app/user/login',
			useCaptcha:          false,
			useTrackers:         false
		}
	}
	config.secureServerUrl = config.serverUrl.replace('topsripitwinit.com', 'topsripitwinitcodes.com');
	return config;
}

/**
 * this.$config is intended to host static config values.
 * These are values that aren't provided from external data and should never change throughout the lifetime of the app.
 * Anything else should be referenced from one of the global $store module files (frontend/src/store/modules/*.js)
 * A good example is $store.get('auth/sessionData')
 *
 * @type {{appVersion: string, useTrackers: boolean, serverUrl: (string|*), env: (string|*), banners: string[], useCaptcha: boolean}}
 */
let config = {
	env:                 configValues().NODE_ENV,
	serverUrl:           configValues().serverUrl,
	secureServerUrl:     configValues().secureServerUrl,
	appVersion:          process.env.VUE_APP_APP_VERSION,
	hostRegistrationUrl: configValues().hostRegistrationUrl,
	useTrackers:         configValues().useTrackers,
	useCaptcha:          configValues().useCaptcha,
	// the key used on the Choose Store page matters, since the server is expecting specific values
	banners: {},

	recaptcha: {
		siteKey: '6LcZLKUUAAAAAGOVPLHs0a085XO98yi6nXgzLz_2'
	}
}
config.storeListUrl = config.serverUrl + '/app/stores';
config.localUrl = config.serverUrl;
if (process.env.VUE_APP_APP_VERSION) {
	let match = location.href.match(/^(.*)\/index.html(?:#.*)?$/i);
	if (match) {
		config.localUrl = match[1];
	}
}

export default config;
