<template>
    <div class="container-fluid">
		<h1 class="text-center page-title text-light">Enter Code</h1>
		<!-- <p class="mx-auto text-center mb-3 mb-md-5 lead">Win Free Groceries For A Year, 100,000 TOPS GasPoints, $100 Cart Full of Groceries & More! Enter your online codes now!</p> -->
		<div class="pt-4 pt-xl-5">
            <div class="container bg-white rounded" v-if="!missingPhoneNumber">
                <div class="row">
					<div class="col-lg-8">
						<form ref="form" @submit.prevent="submitCode" class="p-4 p-md-5">
							<div class="form-group">
								<div class="alert alert-info text-center" v-if="message">{{ message }}</div>
								<label for="code" class="h5 text-center d-block">Enter a code below</label>
								<input type="text"
										v-model="code"
										id="code"
										ref="codeInput"
										class="form-control code-input text-center text-uppercase"
										autocomplete="off"
										spellcheck="false"
										:placeholder="$store.getters.getLang('enter-code-code-placeholder', 'Enter game code here')">
								<small class="helper-text d-block text-center">Dashes optional</small>
							</div>
							<div class="scanButtons text-center">
								<button type="submit" class="btn btn-primary btn-submit-code btn-rounded purple-gradient-vertical px-5" ref="btnSubmit" data-style="zoom-out" v-ada @click="returnFocusEl = $event.target">Submit</button>
							</div>
							<template v-if="showScanButtons" class="scanButtons text-center">
								<button class="btn btn-primary mt-3 btn-scan btn-rounded purple-gradient-vertical px-5" type="button" @click="scanCode($event)" v-ada>Scan Code
								</button>
							</template>
						</form>
					</div>
					<div class="col-lg-4">
						<div class=" h-100 d-flex align-items-center justify-content-center">
							<img v-if="isCustomer()" src="@/assets/images/EnterCode-purple.png" alt="Rip It! Win It! Reach For The Stars!" class="img-fluid position-relative pull-up-65 pb-3 pb-md-0">
							<img v-else src="@/assets/images/EnterCode-green.png" alt="Rip It! Win It! Reach For The Stars!" class="img-fluid position-relative pull-up-65 pb-3 pb-md-0">
						</div>
					</div>
                </div>
            </div>
            <div class="container mt-100" v-if="!missingPhoneNumber">
                <div class="row">
					<div class="flex-fill bg-white rounded box-shadow " :class="(isCustomer())?'mr-lg-4':''" v-if="isCustomer()">
						<div class="h-100 text-center d-flex flex-column align-items-center justify-content-between">
							<img src="@/assets/images/winner.png" alt="" class="img-fluid position-relative pull-up-30">
                            <div class="pb-5">
                                <h3>THE WINNERS</h3>
                                <router-link class="h5 text-decoration-none color-purple font-weight-bold px-2" :to="{ path: '/winners'}">TAKE A LOOK AT THE CURRENT WINNERS</router-link>
                            </div>
                        </div>
                    </div>
					<div class="flex-fill bg-white rounded box-shadow mt-100 mt-md-0 " :class="(isCustomer())?'ml-lg-4':''">
						<div class="h-100 text-center d-flex flex-column align-items-center justify-content-between">
							<img src="@/assets/images/faq-3.png" alt="" class="img-fluid position-relative pull-up-30">
                            <div class="pb-5">
                                <h3>HAVE QUESTIONS</h3>
                                <router-link class="h5 text-decoration-none color-purple font-weight-bold px-2" :to="{name: 'faq'}">CHECK OUT OUR FAQ PAGE FOR ANSWERS</router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="container bg-white rounded mt-100" v-if="!missingPhoneNumber"> -->
            <!--     <div class="row"> -->
			<!-- 		<div class="col-lg-8"> -->
			<!-- 			<div class="p-4 p-md-5"> -->
            <!--                 <h3>REDEEM YOUR REWARDS</h3> -->
            <!--                 <p class="font-weight-bold mb-0 mt-3">Do you have tokens?</p>
            <!--                 <p class="mb-3">Visit the rewards page to see what you can get this week!</p>
                             <!-- <router-link class="btn btn-primary btn-rounded purple-gradient-vertical px-5"" :to="{name: 'magic-tokens'}">Spend Magic Tokens</router-link> -->
            <!--                 <router-link class="btn btn-primary btn-rounded purple-gradient-vertical px-5"" :to="{path: '/history'}">Spend Magic Tokens</router-link> -->
            <!--             </div> -->
			<!-- 		</div> -->
			<!-- 		<div class="col-lg-4"> -->
			<!-- 			<div class=" h-100 d-flex align-items-center justify-content-center"> -->
			<!-- 				<img src="@/assets/images/redeem.png" alt="" class="img-fluid position-relative pull-up-65 pb-3 pb-md-0"> -->
			<!-- 			</div> -->
			<!-- 		</div> -->
            <!--     </div> -->
            <!-- </div> -->
		</div>
        <!-- <page-sub-section :cols="['winners', 'faq']"></page-sub-section> -->

        <tracker v-if="$config.useTrackers" name="Enter Code" ></tracker>

    </div>
</template>

<style lang="scss" scoped>

.lead {
	width: 100%;
	@media(min-width: $md) {
		width: 75%;
	}
}
.pull-up-65 {
    position: relative;
	@media(min-width: $md) {
        top: -65px;
    }
}
.pull-up-30 {
    position: relative;
    top: -30px;
}
</style>

<script>
import Raven from "raven-js";
import store from 'store';

import vuexStore from '../store';
import * as ladda from "ladda";

import tracker from "../components/trackers/General";

export default {
    name:       "enter-code",
    components: {
        tracker,
    },
    data() {
        return {
            code:           null,
            codeScanned:  false,
            message:      null,
            counter:      0,
            showSlider:   true,
            slickOptions: {
                dots:      true,
                infinite:  true,
                speed:     500,
                fade:      true,
                cssEase:   "linear",
                prevArrow: '<button type="button" class="slick-prev"><span class="sr-only">Previous Slide</span></button>',
                nextArrow: '<button type="button" class="slick-next"><span class="sr-only">Next Slide</span></button>'
            },

            returnFocusEl: null
        };
    },

    computed: {
        isDev() {
            return this.$config.env === 'local'
        },
        lastPlayedCode() {
            if (this.isDev) {
                return this.$store.get('auth/lastPlayedCode');
            }
            return null;
        },
        sliderContent() {
            let data = this.$store.get("auth/user");
            let sliders = data && data.sliders || [];
            return sliders.filter(slide => {
                return slide.where.indexOf('code-entry') >= 0;
            });
        },
        missingPhoneNumber() {
            return this.$store.get("auth/missingPhoneNumber");
        },
        match3GameInProgress() {
            return this.$store.get("auth/user@playMatch3Game");
        },
        showScanButtons() {
            return  this.$store.get('global/isApp');
        }
    },
    mounted() {
        this.watchConnectivity(true);
        this.$watch(
            "missingPhoneNumber",
            to => {
                if (to) {
                    this.$modalService.create("codeEntry/getPhoneNumber", {
                        backdrop: "static"
                    });
                }
            },
            {immediate: true}
        );

        if (this.$store.get("global/codeScanned")) {
            this.code = this.$store.get("global/codeScanned");
            this.$store.set("global/codeScanned", null);
            this.submitCode({
                scanned: true
            });
        }

        if (/\.local$|qa\./.test(location.hostname) || location.port==='8080'){
            if (this.$route.params.codeToEnter){
                this.$nextTick(()=>{
                    console.log('submitting the code');
                    this.submitCode({code:this.$route.params.codeToEnter});
                })
            }
        }
    },
    beforeRouteEnter(to, from, next) {
        if (!vuexStore.get('auth/allowEnterCodes')){
            let nextPage = vuexStore.get('auth/defaultPlayerLandingPageName');
            next({name: nextPage});
            return;
        }

        next();
    },
    beforeDestroy() {
        let modal = $(this.$refs.slotmodal);
        if (modal.hasClass("in")) {
            modal.modal("hide");
        }

        modal.off("shown.bs.modal hidden.bs.modal");

        this.watchConnectivity(false);
    },

    methods: {

        testCouponModal() {
            this.code = this.lastPlayedCode;
            this.$nextTick(() => {
               $('.btn-submit-code').click();
            });
        },
        scanCode(e) {
            this.returnFocusEl = e.target;

            let that    = this,
                scanBtn = $(this.$el).find('.btn-scan');

            if (this.softDisabled(scanBtn)) {
                return;
            }

            this.$EventBus.$on("backbutton", that.cancelBackButton);
            this.softDisabled(scanBtn, true);

            cordova.plugins.barcodeScanner.scan(
                function (result) {
                    that.softDisabled(scanBtn, false);
                    that.$EventBus.$off("backbutton", that.cancelBackButton);

                    if (result.cancelled) {
                        plugins.toast.showShortTop("Scan cancelled");
                        return;
                    }

                    // if (result.format === "DATA_MATRIX") {
                    //     that.$store.set("global/codeScanned", result.text);
                    //     that.$router.push({name: "track-markers"});
                    //     return;
                    // }

                    that.code = result.text;
                    that.submitCode({
                        scanned: true
                    });
                },
                function (error) {
                    that.softDisabled(scanBtn, false);
                    this.$EventBus.$off("backbutton", that.cancelBackButton);

                    let lowerCaseErr =
                            typeof error === "string"
                                ? error.toLowerCase()
                                : error.message.toLowerCase();
                    if (lowerCaseErr.indexOf("illegal access") >= 0) {
                        plugins.toast.showShortTop("Scan cancelled");
                    } else if (
                        lowerCaseErr.indexOf(
                            "access to the camera has been prohibited"
                        ) >= 0
                    ) {
                        plugins.toast.showShortTop(
                            "Camera disabled: Please enable it in your settings app"
                        );
                    } else if (
                        lowerCaseErr.indexOf("scan is already in progress") >= 0
                    ) {
                        plugins.toast.showShortTop("Scan already in progress");
                    } else {
                        Raven.captureException(
                            typeof error === "string" ? new Error(error) : error
                        );
                        gaU("send", "event", "Scanner", "Scan Failed");
                    }
                },
                {
                    preferFrontCamera:     false, // iOS and Android
                    showFlipCameraButton:  true, // iOS and Android
                    showTorchButton:       true, // iOS and Android
                    torchOn:               false, // Android, launch with the torch switched on (if available)
                    saveHistory:           true, // Android, save scan history (default false)
                    prompt:
                                           "Trouble Scanning? Try placing a white piece of paper behind your game piece.", // Android
                    resultDisplayDuration: 500, // Android, display scanned text for X ms. 0 suppresses it entirely, default 1500
                    formats:               "DATA_MATRIX, CODE_128, UPC_A, UPC_E", // default: all but PDF_417 and RSS_EXPANDED
                    //                        orientation : "landscape", // Android only (portrait|landscape), default unset so it rotates with the device
                    disableAnimations:     true, // iOS
                    disableSuccessBeep:    true // iOS and Android
                }
            );
        },
        cancelBackButton(opts) {
            opts.cancel = true;
        },
        validateCode(code) {
            // todo replace anything not a alphanumeric, take first 12
            // code.replace(/[^A-Z\d]/gi, "").substr(0, 12),
            let result = {
                code: code.replace(/\s+|-/g, ""),
                type: "game"
            };


            /*if (result.code.match(/^(?:S|[123456789ABCDFGHKLMNPRTVWXYZ])[123456789ABCDFGHKLMNPRTVWXYZ]{15}$/i)) {
                                          result.type = 'game';
                                      }
                                      else*/
            /* if (result.code.match(/^11[A-Z]{4}\d{4}$|^[RO][A-Z\d]{6}\w$/i)) {
                result.type = "inventory";
            } */

            return result;
        },

        employeeCodeEntered: function (code) {
            this.$modalService.create("codeEntry/generic", {
                title:   "Employee Code",
                message: `${code} is an employee code and cannot be played on this account`
            })
                .setReturnElement(this.returnFocusEl);
        },
        customerCodeEntered: function (code) {
            this.$modalService.create("codeEntry/generic", {
                title:   "Customer Code",
                message: `${code} is a customer code and cannot be played on this account`
            })
                .setReturnElement(this.returnFocusEl);
        },

        wonPrize: function (info) {
            return new Promise((resolve) => {
                if ("modal" in info) {
                    let modalName = info.modal.messageType || 'generic';
                    info.modal.isWinner = true;
                    this.$modalService
                        .create(`codeEntry/${modalName}`, info.modal)
                        .setReturnElement(this.returnFocusEl)
                        .on("close", () => {
                        // Allow the promise in submitCode to continue
                        resolve();
                    });
                    return;
                }

                // prizeWon modal is a default message that probably should never appear, as
                // we now show the slot machine instead
                let prizeNotification = "generic";
                if (info.prize && info.prize.messageType) {
                    prizeNotification = info.prize.messageType;
                }
                info.isWinner = true;
                // slot machine wants an array of numbers:
                // if (info.slot_machine) {
                //     info.slot_machine = info.slot_machine.split(",");
                //     prizeNotification = "slotMachine";
                // }
                this.$modalService
                    .create(`codeEntry/${prizeNotification}`, {info})
                    .setReturnElement(this.returnFocusEl)
                    .on("close", () => {
                        // Allow the promise in submitCode to continue
                        resolve();
                    });
            });
        },

        isCustomer: function() {
            let userType = this.$store.get('auth/currentUserType').toLowerCase();
            return userType == 'customer';
        },

        notAWinner:                      function (code) {
            // this.message = `Sorry, ${code} is not a winning code.`
            this.$modalService.create("codeEntry/generic", {
                title:   "Try Again",
                message: `Your code ${code} is not a winning code. Thanks for playing!`
            })
                .setReturnElement(this.returnFocusEl);
        },
        alreadyEnteredThisCode:          function (code) {
            // this.message = `You have already entered the code ${code}`
            this.$modalService.create("codeEntry/generic", {
                title:   "Duplicate Code",
                message: `Your code ${code} has already been submitted. Please try again.`
            })
                .setReturnElement(this.returnFocusEl);
        },
        alreadyEnteredThisCodeContactCS: function (code) {
            this.$modalService.create("codeEntry/generic", {
                title:   "Invalid Code",
                message: `${code} has previously been entered by another player - please contact Customer Service`
            })
                .setReturnElement(this.returnFocusEl);
        },
        mustAgreeToTerms:                function (code) {
            this.code = code;
            this.$modalService
                .create("codeEntry/customerToEmployee")
                .setReturnElement(this.returnFocusEl)
                .on("continue", () => {
                    this.submitCode({
                        code,
                        agree: true
                    });
                });
        },
        lockForm(disable) {
            let els = $(this.$refs.codeInput)
                .add($(this.$el).find(':submit'))
                .add($(this.$el).find('form'));

            this.softDisabled(els, disable);
        },
        submitCode(opts) {
            let form = $(this.$el).find('form');
            if (this.softDisabled(form)) {
                return;
            }

            if (this.$store.get("global/offline")) {
                this.$modalService.create("offlineModal", {
                    title:   "App Offline",
                    message: "Please reconnect to the internet..."
                })
                    .setReturnElement(this.returnFocusEl);

                return;
            }

            opts = $.extend({agree: false}, opts);

            if (typeof opts.scanned === "boolean") {
                this.codeScanned = true;
            }
            if ("code" in opts) {
                this.code = opts.code;
            }
            this.message = null;

            // Check code type
            let codeType = this.validateCode(this.code || "");

            let data = {
                code:    codeType.code,
                scanned: this.codeScanned ? 1 : ""
            };

            this.lockForm(true);

            let promise = Promise.resolve();
            if (codeType.type === "game") {		// This is always the type. See validateCode
                data = $.extend(data, {
                    agree_to_terms: opts.agree
                });

                promise = this.enterGameCode(data);
            }
            let unlockForm = () => {
                this.lockForm(false);
                this.code = "";
                this.codeScanned = false;
            };
            promise.then(unlockForm, unlockForm);
        },

        enterGameCode(data) {
            this.message = null;
            return new Promise((resolve, reject) => {
                this.$store.set('auth/lastPlayedCode', data.code);
                this.$http
                    .post("/game/enterCode", data)
                    .then(response => {
                        // if agreed to terms to change usertype, or a prize is won, we must refresh the userdata:
                        if (data.agree_to_terms ||  response.data.result==='W') {
                            let delay = response.data.slot_machine ? 3000 : 0;
                            setTimeout(() => {
                                this.$store.dispatch("auth/refreshUser").then(() => {
                                    this.showSlider = false;
                                    this.$nextTick(() => {
                                        this.showSlider = true;
                                    });
                                })
                            }, delay);
                        }
                        let info = response.data;
                        switch (info.result) {
                            case "I":
                                return this.invalidCode(info);
                            case "C":
                                return this.customerCodeEntered(info.code);
                            case "T":
                                return this.employeeCodeEntered(info.code);
                            case "U":
                                return this.mustAgreeToTerms(info.code);
                            case "D":
                                return this.alreadyEnteredThisCode(info.code);
                            case "X":
                                return this.alreadyEnteredThisCodeContactCS(info.code);
                            case "M":
                                // start Match 3 game
                                // every page navigation results in a ping to /user, so this setter is not needed.
                                // this.$store.set('auth/setMatch3Game', info.match3GameData);
                                this.$router.replace({name: 'match'});
                                return;
                            // these three will need extra work done
                            case "V":
                                return this.notAWinner(info.code);
                            case "Z":
                                return this.showMessage(info);
                            case "W":
                                return this.wonPrize(info);
                        }
                    }, (error) => {
                        // Error when sending the request
                        if (error.response && error.response.status === 401) {
                            // this.$store.set('auth/ready', false);
                            this.$store.dispatch('auth/refreshUser').then((user) => {
                                if (user) {
                                    this.$router.replace({name: 'enter-code'});
                                } else {
                                    this.$router.replace({name: 'play'});
                                }
                            });
                            return;
                        }
                        if (!error.response || (error.response && error.response.status >= 500)) {
                            this.message = "Sorry, your request can not be processed at this time. Please try again in a few minutes.";
                        } else {
                            this.message = this.errorResponseToArray(error).join('<BR>')
                        }
                    })
                    .catch(error => {
                        // Error from trying to handle the response
                        this.message = "We processed the request but have run into a problem displaying the result.";
                    })
                    .then(() => {
                        resolve();
                    });
            });
        },
        showMessage(info) {
            this.$modalService.create("codeEntry/generic", info).setReturnElement(this.returnFocusEl);
        },
        invalidCode(response) {
            let modal = {
                title:   response.title || "Invalid Code",
                message: response.message || `<p>Your code ${response.code} is not a valid code. Please try again.</p>`
            };
            this.$modalService.create("codeEntry/generic", modal).setReturnElement(this.returnFocusEl);
        }
    }
};
</script>
