import Vue from 'vue';
// import moment from 'moment';
import dayjs from 'dayjs';

import {formatDistance} from 'date-fns'
import {parseISO} from 'date-fns'
import {formatDistanceToNow} from 'date-fns'

Vue.filter('classify', function(value) {
	if (value) {
		return value.replace(/[^a-z0-9]/g, function(s) {
			var c = s.charCodeAt(0);
			if (c == 32) return '-';
			if (c >= 65 && c <= 90) return s.toLowerCase();
			return '__' + ('000' + c.toString(16)).slice(-4);
		});
	}
});

Vue.filter('formatDate', function(value, format, force) {
	if (value || force) {
		return dayjs(String(value)).format(format || 'MM/DD/YYYY h:mm:ss a');
	}
});

Vue.filter('formatNumber', function(value, parens) {
	if (typeof value !== 'undefined') {
		let v = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
		if (parens && v < 0) {
			v *= -1;
			v = `(${v})`;
		}
		return v;
	}
});

Vue.filter('fromNow', function (date) {
  let now = parseISO ('2020-02-07 23:59:01');
  let dateObj = parseISO (date);
  return formatDistanceToNow( dateObj) ;
    // return dayjs(date).fromNow(true);
})
