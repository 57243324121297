import {make} from 'vuex-pathify';
import {EventBus} from '../../utils/EventBus'
import Vue from 'vue';

export default {
	namespaced: true,
	state:      {
		ready: false,
		ready2: false
	},
	mutations:  {
		...make.mutations(['ready', 'ready2'])
	},
	getters:    {
		...make.getters(['ready', 'ready2'])
	},
	actions:    {
		makeReady({state, commit, dispatch}) {
			return new Promise((resolve) => {
				if (state.ready) {
					resolve();
				} else {
					// app initialization
					const doMakeReady = () => {
						// bypass all auth checking for this url
						if (/link-test/.test(location.href)){
							commit('SET_READY', true);
							resolve();
						} else
						if (location.href.match(/\/logout/)){
							Vue.$log.info('[makeReady] logout user');
							dispatch('auth/logoutUser', null, {root: true}).then(() => {
								commit('SET_READY', true);
								resolve();
							});
						} else {
							Vue.$log.info('[makeReady] obtaining user identity');
							dispatch('auth/refreshUser', null, {root: true}).then(() => {
								// commit('SET_READY', true);
								resolve();
							});
						}
					}
					if (process.env.VUE_APP_APP_VERSION) {
						Vue.$log.info('[makeReady] waiting for deviceready');
						EventBus.$when('deviceready', doMakeReady);
					} else {
						doMakeReady();
					}
				}
			});
		}
	}
}
