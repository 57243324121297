import {store} from '@/utils/storage'
import api from '../../utils/axios'
import Vue from 'vue';

function promisify(val) {
	if (!(val instanceof Promise)) {
		val = Promise.resolve(val);
	}

	return val;
}

function forLoopPromise(opts, cb) {
	return new Promise((resolve) => {
		let loop = (v) => {
			promisify(opts.cond(v)).then((truthy) => {
				if (truthy) {
					cb(v).then(() => {
						promisify(opts.step(v)).then(loop);
					});
				} else {
					resolve();
				}
			});
		};

		promisify(opts.start()).then(loop);
	});
}

export default {
	state:     {
		lang:         {},
		faqs:         [],
		winners:      [],
		winners_2017: [],
	},
	mutations: {
		loadJsonFile(state, file) {
			Vue.$log.info('[loadJsonFile] ', file)
			let obj = store.get('json_' + file);

			if (obj) {
				if (Array.isArray(obj)) {
					for (let i = obj.length - 1; i >= 0; i--) {
						Vue.set(state[file], i, obj[i]);
					}
				} else {
					for (let i in obj) {
						Vue.set(state[file], i, obj[i]);
					}
				}
			}
		}
	},
	actions:   {
		/**
		 * Retrieve latest copy of language data from server and store it into localStorage
		 *
		 * @returns {PromiseLike<any> | Promise<any>}
		 */
		refreshJsonFiles({commit}) {
			Vue.$log.info(['refreshJsonFiles']);
			const handleRequestError = response => {
				let url = response && response.config && response.config.url;
				Vue.$log.error('failed to load', url);
			}

			return api.get('../files/lang.json').then(response => {
				let data = response.data;
				if (data) {
					Vue.$log.info('retrieved ', response)
					store.set(`json_lang`, data);
					commit('loadJsonFile', 'lang');
				} else {
					handleRequestError(response);
				}
			}, handleRequestError);
		},

		refreshJsonFilesOld({commit}) {
			let files = ['lang', 'faqs', 'winners', 'winners_2017'];

			return new Promise((resolve) => {
				// compare manifest
				api.get('/json/manifest.json').then((liveVersion) => {
					let manifest = liveVersion.data;

					forLoopPromise({
						start() {
							return 0
						},
						cond(i) {
							return i < files.length
						},
						step(i) {
							return i + 1
						}
					}, (i) => {
						return new Promise((r) => {
							if (!manifest[files[i]]) {
								// wipe local copy
								store.set('json_version_' + files[i], false);
								store.set('json_' + files[i], false);
								return r();
							}

							let localVersion = store.get('json_version_' + files[i]);

							if (localVersion != manifest[files[i]]) {
								api.get('/json/' + files[i] + '.json').then((resp) => {
									store.set('json_version_' + files[i], manifest[files[i]]);
									store.set('json_' + files[i], resp.data);
									r();
								}, r);
							} else {
								r();
							}
						});
					}).then(resolve);
				}, resolve);

			}).then(() => {
				for (let i = 0; i < files.length; i++) {
					commit('loadJsonFile', files[i]);
				}
			});
		}
	},
	getters:   {
		getLang(state) {
			return (id, def) => {
				return state.lang[id] || (def !== undefined ? def : false);
			};
		},
		getFaqs(state) {
			return state.faqs.length ? state.faqs : false;
		},
		getWinners(state) {
			return state.winners.length ? state.winners : false;
		},
		getWinners2017(state) {
			return state.winners_2017.length ? state.winners_2017 : false;
		}
	}
}
