<template>
    <div class="container container-wide">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <lang id="footer-section-dowload-content" class="footer-section-download-content py-50 py-lg-100">
                    <h3 class="section-title text-uppercase">
                        <span class="font-size-small d-block mb-2">Play on your Devices </span>
                        Download the App<span aria-describedby="msg-rates-apply">*</span></h3>
                    <p class="font-size-large color-light">
                        Download the new TOPS&reg; Rip It & Win It app featuring exclusive digital only prizes! Use the ticket scanning feature to submit digital codes and see game results faster.
                    </p>
                    <p class="font-size-small color-light" id="msg-rates-apply">*Standard message fees and data rates may apply.</p>
                    <div class="download-btn-group d-flex mr-lg-5 pr-lg-5">
<!--                        <a :href="url" class="btn btn-primary d-flex align-items-center text-nowrap">Launch App</a>-->
<!--                        <btn-download-app android class="m-2 w-100 btn-android" ref="d"></btn-download-app>-->
<!--                        <btn-download-app ios class="m-2 w-100 btn-ios" ref="a"></btn-download-app>-->
                    </div>
                </lang>
            </div>
            <div class="col-6 col-lg-3 align-self-end col-phone mx-auto">
            </div>
        </div>
    </div>
</template>

<script>
// import platform from 'platform';
import BtnDownloadApp from '../components/btn-download-app';

export default {
    name:       "download-app",
    components: {BtnDownloadApp},
    computed:
                {
                    url() {
                        let token = 'Gx2NRROywATbYd5L5NLIEtZGNIh4H5JKNiXRUm|0000|2102151801|Card|0|',
                            url   = "topsripitwinit://?token=" + token;
                        return url;
                    }
                },
    methods:    {

    },
    mounted() {
    }
}
</script>

<style scoped lang="scss">


</style>
