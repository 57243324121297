import config from './config'
import Vue from 'vue'
import VueRouter from 'vue-router';
import AsyncComputed from '../utils/AsyncComputed'
import {ModalService} from '../utils/ModalService'
// import Raven from 'raven-js';
// import RavenVue from 'raven-js/plugins/vue';
import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';

import axios from '../utils/axios'

import {getStorage, setStorage} from '../utils/storage'
import {EventBus} from "../utils/EventBus";
import * as ladda from 'ladda';

import VueFormulate from '@braid/vue-formulate'

import './directives';

// Only initialize sentry.io on apps
let useSentry =config.appVersion /*|| config.env === 'local' */ || config.env === 'staging';
useSentry = false;
if (useSentry) {
	const launchDate = new Date('2021-03-07');
	const now = new Date();
	let sampleRate = (config.appVersion || config.env === 'production') && now > launchDate ? 0.25 : 1;
	let release = config.appVersion ? `spw-app-v${config.appVersion}` : 'topsripitwinit.com';

	Sentry.init({
		dsn:          '',
		integrations: [new Integrations.Vue({
			Vue,
			logErrors: true
		})],
		release:      release,
		environment:  config.env,
		sampleRate
	});
	Object.assign(Vue.prototype, {
		$sentry: Sentry
	});
}

//  this.$log.info('message', extraData)
import VueLogger from 'vuejs-logger';

const options = {
	isEnabled:          true,
	logLevel:           (config.env === 'development' || config.appVersion) ? 'debug' : 'error',
	stringifyArguments: false,
	showLogLevel:       false,
	showMethodName:     false,
	separator:          null,
	showConsoleColors:  true
};
Vue.use(VueLogger, options);

Vue.use(AsyncComputed);
Vue.use(VueRouter);
Vue.use(VueFormulate);

let laddaMap = {};
Vue.mixin({
	components: Vue.options.components,
	methods:    {
		/**
		 * Create, and start/stop, a button that has a busy state indicator.
		 * Uses ladda.
		 * @param el
		 * @param start
		 * @param id must be unique per button
		 */
		busyButton(el, start, id) {
			let $el = $(el).closest('.btn');
			if (!laddaMap[id])
				laddaMap[id] = ladda.create($el[0]);

			if (start) {
				laddaMap[id].start();
			} else {
				laddaMap[id].stop();
			}
		},

		watchConnectivity(enable) {
			enable = typeof enable == 'undefined' ? true : !!enable;

			if (this.connectivityWatcher) {
				this.connectivityWatcher();
				this.connectivityWatcher = null;
			}

			if (enable) {
				this.connectivityWatcher = this.$watch('$store.getters.isOffline', (to) => {
					if (to) {
						this.$modalService
							.create('offlineModal', {
								title:   'App Offline',
								message: 'Please reconnect to the internet...',
								opts:    {
									backdrop: 'static',
									keyboard: false,
									closeBtn: false
								}
							});
					} else {
						getStorage('temp_auth', '', (val) => {
							if (val === true) {
								setStorage('temp_auth', '', () => {
									window.location.reload();
								});
							} else {
								this.$modalService.getVisibleModals().forEach((modal) => {
									if (modal.modalType == 'offlineModal') {
										modal.trigger('close');
									}
								});
							}
						});
					}
				}, {immediate: true});
			}
		},

		colorToHSP(color) {
			if (color[0] == '#') {
				color = color.substr(1);
			}

			var matches, r, g, b;

			if (matches = color.match(/rgb\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})\s*\)/)) {
				r = matches[1];
				g = matches[2];
				b = matches[3];
			} else {
				if (color.length == 3) {
					color = color
						.split('')
						.map(function (a) {
							return a + a;
						})
						.join('');
				}

				// Variables for red, green, blue values
				r = parseInt(color.substr(0, 2), 16);
				g = parseInt(color.substr(2, 2), 16);
				b = parseInt(color.substr(4), 16);
			}

			// HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
			var hsp = Math.sqrt(
				0.299 * (r * r) +
				0.587 * (g * g) +
				0.114 * (b * b)
			);

			return hsp;
		},

		getOppositeColor(color) {
			if (this.colorToHSP(color) > 127.5) {
				return 'black';
			} else {
				return 'white';
			}
		},

		/**
		 * Extracts the error information out of a response object, places it into a simple array.
		 * @param error
		 * @returns {Array}
		 */
		errorResponseToArray(error) {
			if (error.bodyJSON && !error.response) {
				error.response = {data: error.bodyJSON};
			}
			let errors = [];
			if (error.response && error.response.data && error.response.data.errors) {
				errors = error.response.data.errors;
				if (!Array.isArray(errors)) {
					errors = Object.values(errors);
				}
			} else if (error.response && error.response.data && error.response.data.messages) {
				errors = error.response.data.errors;
				if (!Array.isArray(errors)) {
					errors = Object.values(errors);
				}
			} else if (error.response && error.response.data && error.response.data.error) {
				errors = [error.response.data.error];
			} else {
				errors = [`Sorry, your request could not be processed at this time. Please try again in a few minutes.`]
				if (error.request && error.request.responseText) {
					errors = [error.request.responseText];
				} else if (error.body) {
					errors = [error.body];
				}
			}
			return errors;
		},

		alertADA(message) {
			this.$store.set('ada/alert', message);
		},

		softDisabled(els, status) {
			if (typeof status == 'undefined') {
				return $(els).data('disabled');
			} else {
				status = !!status;
				$(els).each(function () {
					let that = $(this);

					that.data('disabled', status).toggleClass('disabled', status);
					if (status) {
						that.attr('aria-disabled', 'true');
					} else {
						that.removeAttr('aria-disabled');
					}

					if (typeof this.readOnly != 'undefined') {
						this.readOnly = status;
					}
				});
			}
		},

		slugify(input) {
			return 'slugify-' + MD5({input});
		}

	}//methods
});

import Slick from 'vue-slick';

// Vue.directive('flickity', {
//     inserted(el, binding) {
//         el.flkty = new Flickity(el, binding.value);
//     },
//
//     /*componentUpdated(e, binding) {
//
//     },*/
//
//     unbind(el, binding) {
//         el.flkty.destroy();
//         delete el.flkty;
//     }
// });

Vue.use(Slick);

import {VBTooltip} from 'bootstrap-vue'
// Note: Vue automatically prefixes the directive name with 'v-'
Vue.directive('b-tooltip', VBTooltip)

Object.assign(Vue.prototype, {
	$config:       config,
	$http:         axios,
	$EventBus:     EventBus,
	$modalService: ModalService
	// todo add store, getStorage, setStorage?
});
