<template>
    <div class="container-fluid">
        <h1 class="text-center page-title">Mr. Monopoly Magic Tokens</h1>
		<p class="w-75 mx-auto text-center mb-5 lead"><span class="text-uppercase">Online Exclusive!</span> Don't have this week's Lucky Token advertised in ad? Enter your online codes and you could reveal a Mr. Monopoly Magic Token, which can unlock this week's deal - or any future deal of your choice! If you would like to redeem a Mr. Monopoly Magic Token, simply click this week's offer below to activate it for this week's deal!</p>
        <div class="bg-white py-3 px-3">
            <img src="../assets/images/magic-token-lg.png" alt="" width="32" class="mr-3">

            <span class="h6 color-light">Magic Tokens:</span>
            <span class="h6 color-yellow">{{ this.$store.get('auth/numberOfTokens') }}</span>
        </div>

        <div class="bg-white mt-3" v-if="coupon">
            <div class="row">
                <div class="col-sm-6" v-if="imageUrl">
                    <img :src="imageUrl" :alt="`Product photo for ${coupon.name}`" class="img-fluid">
                </div>
                <div class="col-sm-6 text-center d-flex align-items-center">
                    <div class="col-sm-8 mx-auto my-3">
                        <div class="h6 color-light mb-4" v-html="headline"></div>
                        <div class="h3" v-html="coupon.name">Chobani Greek Yogurt</div>
                        <div v-html="coupon.desc"></div>
                        <div class="btn btn-primary btn-block mt-3" v-if="isClipable" @click="spend">Spend Token</div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else class="row d-flex flex-column flex-lg-row justify-content-center">
			<div class="col-lg-6">
				<img src="../assets/images/magic-token.jpg" alt="" class="img-fluid mx-auto d-block">
			</div>
			<div class="col-lg-6">
				<img src="../assets/images/magic-token-ad.jpg" alt="" class="img-fluid mx-auto d-block">
			</div>
        </div>
        <page-sub-section :cols="['history-left', 'faq']"></page-sub-section>
    </div>
</template>

<script>
import {get} from "vuex-pathify";
import vuexStore from '../store'

export default {
    name: "magic-tokens",

    data() {
        return {};
    },
    computed: {
        isClipable(){
            if (!(this.$store.get('auth/numberOfTokens')>0)){
                return false;
            }
            if (this.coupon.noclip){
                return false;
            }
            return true;
        },
        coupon(){
          return   this.$store.get('auth/sessionData@weeklyCoupon') ;
        },
        imageUrl(){
            return this.coupon.image_url;
        },
        headline(){
          return this.coupon.headline || `This Week's Offer`;
        }
    },
    created() {
    },

    mounted() {
        this.watchConnectivity(true);
    },
    beforeDestroy() {
        this.watchConnectivity(false);
    },

    methods: {
        spend() {
            this.$modalService.create("confirm", {
                message: `You are about to redeem 1 Magic Token for this week's offer.`
            }).setReturnElement(this.returnFocusEl)
                .on('continue', this.doSpendToken);
        },
        showError(xhrError) {
            this.$modalService.close();
            this.$modalService.create('generic', {
                title:   'Sorry...',
                message: 'Something went wrong and the coupon could not be added. Please try again in a few minutes.'
            });
        },
        async doSpendToken() {
            let {data} = await this.$http.post('/game/redeemPrizeWithTokens').catch(this.showError);
            if (data) {
                this.$store.set('auth/tokensAvailable', data.tokensAvailable);
                await this.$store.dispatch('auth/refreshUser')
                this.$modalService.close();
                this.$modalService.create('codeEntry/generic', data.modal);
            }
        }
    },
    beforeRouteEnter(to, from, next) {
        if (!vuexStore.get('auth/allowSpendTokens')) {
            next({name: 'home'});
            return;
        }

        next();
    },
    watch: {}
};
</script>
<style lang="scss" scoped>

</style>
