<template>
	<modal classes="modal-red" size="lg">
		<h5 slot="header" slot-scope="{id}" :id="id">
			<lang id="customer-to-employee-title">
				Associate Code Entered
			</lang>
		</h5>
		<form slot="body" ref="agreeToTermsForm">
			<div class="form-group">
				<lang id="customer-to-employee-content">
					<p>To continue, you must agree to the Associate Rules &amp; Regulations.</p>
				</lang>

				<input class="sr-only sr-only-checkbox" type="checkbox" id="agree_to_terms_for_code_entry" v-model="agreeToTerms">
				<label class="check-box"  for="agree_to_terms_for_code_entry" role="presentation"></label>
				<label class="check-box-label align-top ml-1" for="agree_to_terms_for_code_entry">I agree to the <a href="javascript:void(0)" @click.prevent="showRules($event)">rules &amp; regulations</a> for Associates.</label>
			</div>
		</form>
		<div slot="footer" class="btn-block">
			<button type="button" class="btn btn-primary btn-block btn-rounded purple-gradient-vertical" :disabled="!agreeToTerms" @click="submit" v-ada>Continue</button>
			<button type="button" class="btn btn-primary btn-block btn-rounded purple-gradient-vertical" @click="modal.trigger('close')" v-ada>Close</button>
		</div>
	</modal>
</template>

<script>
	export default {
		name: 'customerToEmployee',

		props: {
			modal: {
				type: Object,
				required: true
			},
			agree: {
				type: Boolean,
				default: false
			}
		},

		data() {
			return {
				agreeToTerms: this.agree
			}
		},

		methods: {
			submit() {
				this.modal.trigger('continue close');
			},

			showRules(e) {
				this.$modalService
					.create('Rules', {
						type: 'associate'
					})
					.setReturnElement(e.target);
			}
		}
	}
</script>
