<template>
	<div class="container-fluid px-lg-5">
		<h1 class="text-center page-title text-light">Winners</h1>


		<div class="pt-4 pt-xl-5">
			<!-- Sample data. Max of 12 only. -->
			<div class="winners-grid" v-if="showWinners && winners">
				<div v-for="(winner, index) in filteredChecks" :key="index" class="grid-item text-center">
					<div class="bg-white rounded box-shadow h-100 p-5 p-lg-5">
                        <img src="@/assets/images/winner.png" alt="" class="img-fluid position-relative pull-up-75">
						<h2 class="h1">{{ winner.name }}</h2>
						<strong class="prize-value h3">{{ winner.prize }}</strong>
					</div>
				</div>
			</div>
            <div class="container bg-white p-4 p-xl-5 rounded" v-else>
                <div class="text-dark text-center font-weight-bold">No winners at the moment.</div>
            </div>

			<!-- <div class="text-center font-size-h4" v-if="!showWinners"> -->
                <!-- Coming Soon!-->
			<!-- </div> -->
        </div>


	</div>
</template>


<script>
	import Vue from "vue";
	import {get} from "vuex-pathify";
	import {format} from 'date-fns';

	export default {
		name: "winners",

		components: {
		},

		data() {
			return {
				slickOptions:         {
					autoplay:      true,
					autoplaySpeed: 1500,
					arrows:        false
				},
				showWinners:          this.$store.get('auth/sessionData').showWinners,
				showCustomHtmlBlock1: this.$store.get('auth/sessionData').showWinnersHtmlBlock1,
				showCustomHtmlBlock2: this.$store.get('auth/sessionData').showWinnersHtmlBlock2,
				lastYear:             format(new Date() - 1, 'yyyy'),
				thisYear:             format(new Date(), 'yyyy')
			};
		},
		asyncComputed: {
			/**
			 * Create the file winners.json inside these two directories:
			 * ./public/static/files/ (this does not exist for this game. why?)
			 * ./src/frontend/static/files/
			 *
			 */
			winners:                 {
				get() {
					if (!this.showWinners) {
						return null;
					}
					return new Promise((resolve, reject) => {
						this.$http
							/* .get(this.staticBaseUrl+"winners.json") */
							.get("../files/winners.json")
							.then(response => {
								resolve(response.data);
							})
							.catch(error => {
								this.showWinners = false
								reject(error.message);
							});
					});
				},
			},
			winners_photos_customer: {
				get() {
					if (!this.showWinners || this.userType !== 'customer') {
						return null;
					}
					return new Promise((resolve, reject) => {
						this.$http
							/* .get(this.staticBaseUrl+"winners_photos_customer.json") */
							.get("../files/winners_photos_customer.json")
							.then(response => {
								resolve(response.data);
							})
							.catch(error => {
								reject(error.message);
							});
					});
				},
			},
			winners_photos_employee: {
				get() {
					if (!this.showWinners || this.userType !== 'employee') {
						return null;
					}
					return new Promise((resolve, reject) => {
						this.$http
							/* .get(this.staticBaseUrl+"winners_photos_employee.json") */
							.get("../files/winners_photos_employee.json")
							.then(response => {
								resolve(response.data);
							})
							.catch(error => {
								reject(error.message);
							});
					});
				},
			},
			customHtmlBlock1:        {
				get() {
					if (!this.showCustomHtmlBlock1) {
						return null
					}

					return new Promise((resolve, reject) => {
						this.$http
							/* .get(this.staticBaseUrl + "winners_html_block_top.html") */
							.get("../files/winners_html_block_top.html")
							.then(response => {
								resolve(response.data);
							})
							.catch(error => {
								this.showCustomHtmlBlock1 = false
								reject(error.message);
							});
					});
				},
			},
			customHtmlBlock2:        {
				get() {
					if (!this.showCustomHtmlBlock2) {
						return null
					}
					return new Promise((resolve, reject) => {
						this.$http
							/* .get(this.staticBaseUrl + "winners_html_block_bottom.html") */
							.get("../files/winners_html_block_bottom.html")
							.then(response => {
								resolve(response.data);
							})
							.catch(error => {
								this.showCustomHtmlBlock2 = false
								reject(error.message);
							});
					});
				},
			},

		},
		computed:      {
            isDev() {
                return this.$config.env === 'local'
            },
            staticBaseUrl() {
                let origin = this.$config.serverUrl + '/../static/files/';
                if (this.isDev) {
                    origin = location.origin + '/static/files/';
                }
                return origin;
            },
			user: get("auth/user"),

			userType() {
				let userType = this.$store.get('auth/currentUserType') || '';
				return userType.toLowerCase();
			},
			filteredChecks() {
				if (!this.winners) {
					return null;
				}
				return this.winners.winners.filter(check => check.type === this.userType);
			}
		}
	};
</script>

<style lang="scss" scoped>
.pull-up-75 {
    top: -75px;
}
.prize-value {
    color: $purple;
    .employee & {
        color: $employeePrimary;
    }
}
</style>
