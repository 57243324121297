<template>
	<div class="main main-privacy">
		<div class="container">
			<div class="page-header ticket">
				<h1 class="text-center page-title">Privacy Policy</h1>
			</div>
			<div class="box bg-white p-4 p-lg-5">

                <p>UPDATED FEBRUARY 2022</p>
                <p>
                    TOPS Markets<sup>®</sup> (Tops) believes in the importance of protecting its customers' information. Tops values your business and the trust you place in us. To help you better understand how Customer Information (as defined below) is collected, used and protected in our stores, on our websites and in our mobile applications (collectively, the "Services"), this Privacy Policy outlines: (a) our definition of Customer Information, (b) how Customer Information is collected, (c) how Customer Information is used, (d) how you may prevent your Customer Information from being collected or used, and (e) the measures we take to safeguard Customer Information.
                </p>
                <p>
                    Our primary reasons for collecting Customer Information are to help deliver greater value to you and to improve your shopping experience with us. For example, we may use Customer Information to tell you about products, services, special savings, coupons and discounts we believe may be of interest to you, and to advise you of important information such as product recalls.
                </p>
                <p>Our services are designed for users from the United States ("U.S."), and are controlled and operated by us using data centers located in the U.S., which may have different data protection rules than those of your country. By using the Services, you consent to the transfer of your information to, and storage of your information in, our U.S. data centers.
                </p>

                <h2>What is Customer Information?</h2>

                <p>
                    "Customer Information" is information that is obtained by Tops when our customers purchase a product or service in our stores, or when our customers interact with us as described in this Privacy Policy. Customer Information includes both generic information that does not contain any specific information about you, as well as Customer Identifiable Information which is information that identifies or is specific to you. Customer Identifiable Information typically includes your name, address, email address, and/or telephone number.</p>

                    <p>Generic information is information like sales data that does not contain any specific information about you or others ("Generic Information"). Since Generic Information cannot be linked to you or any other specific individual, it may be used by Tops or others for any purpose.</p>

                    <p>Customer Information, as defined in this Privacy Policy, does not include any of your pharmacy records. Your pharmacy records are kept separate and apart from other Customer Information. Importantly, all pharmacy records are kept and maintained in strict accordance with the privacy and confidentiality requirements mandated by federal and state law. Our privacy policy for pharmacy records is described in the Tops Markets Notice of Privacy Practices, which is available on our website or at any of our pharmacy locations. Click here to view the Pharmacy Privacy Policy or speak to your pharmacist for additional information. To the extent that information collected through the Services is patient information provided to obtain pharmacy services, this information is governed by the Pharmacy Privacy Policy and not this Privacy Policy. If you have questions about which policy applies to information you have provided, please contact Tops Consumer Relations toll-free at 1-800-522-2522.</p>

                <h2>Children</h2>

                <p>
                    Our Services are not intended for children under 13 years of age. In compliance with the children's online privacy protection act, children under the age of 13 shall not provide any personal information to us through our website, through our mobile applications, or otherwise. Tops does not knowingly collect personal information on children under the age of 13.
                </p>

                <h2>How is Customer Identifiable Information Collected?</h2>

                <p>
                    Tops collects Customer Identifiable Information from various sources, including the following:
                </p>
                <ul>
                    <li>
                        Information we receive when you use your TOPS BonusPlus<sup>®</sup>;
                    </li>
                    <li>
                        Information you give us on application forms, surveys, store kiosks, sweepstake entries, contests, and rebate submissions;
                    </li>
                    <li>
                        Information you provide to us over the phone, through the mail, or via email;
                    </li>
                    <li>
                        Information we collect through your browser when you visit our website and your browser interacts with our computer systems;
                    </li>
                    <li>
                        Information we collect through mobile applications distributed by Tops; and
                    </li>
                    <li>
                        Information provided to us by other companies that have collected information about you and that have the lawful right to give this information to us.
                    </li>
                </ul>

                <h2>Mobile Devices</h2>

                <p>
                    Tops provides mobile applications ("Apps") that can be downloaded to your mobile device. To provide meaningful notifications about our stores and products, these Apps may collect information about your real-time location. You may adjust the settings on your mobile device to allow or prevent the sharing of location information. We may share information collected through our Apps with third parties.
                </p>

                <h2>How is Customer Identifiable Information used?</h2>

                <p>
                    In order to deliver greater value to you and improve your shopping experience with us, Tops discloses Customer Identifiable Information about you, your purchases and your shopping experiences with Tops to select third parties, marketing partners and marketing service providers that are utilized by Tops and these select third party companies to send you advertisements, offers, and related communications to you and to provide personalized services to you through the mail, through email and through digital and other technologies.
                </p>

                <p>Tops may disclose Customer Identifiable Information to third party marketing companies who accumulate and combine data to produce aggregated information that is not identifiable to you or any other specific individual ("Aggregated Information"). Aggregated Information, like Generic Information, cannot be linked to you or any other specific individual, and is therefore not covered by this Privacy Policy, and may be used by Tops or others for any purpose.</p>

                <p>We may also provide your Customer Identifiable Information to a third party to complete your purchases. For example, if you use a credit or debit card to pay for your purchases at Tops your card number and transaction information will be shared with the payment processor or card issuer (credit or debit card information is only shared as necessary to complete the respective transaction).</p>

                <p>Importantly, we require these third party companies to sign an agreement that prohibits them from using your Customer Identifiable Information for other purposes and/or from disclosing your Customer Identifiable Information to anyone else.</p>

                <p>In addition, Tops may disclose Customer Identifiable Information as required by law, as required to protect the rights or safety of Tops or our customers, or in response to a specific law enforcement request. Tops may also disclose Customer Identifiable Information to its affiliates, and may transfer Customer Identifiable Information in connection with a sale of its business operations, reorganization, merger, sale, joint venture or other disposition of the assets or stock of (or membership interest in) Tops.</p>

                <h2>How can I prevent my Customer Identifiable Information from being collected?</h2>

                <p>
                    You consent to us collecting and using your Customer Identifiable Information in accordance with this Privacy Policy by: (a) signing up for and using your Tops BonusPlus® without opting out of the collection of Customer Identifiable Information, (b) registering on our website, (c) interacting with us through your computer or our mobile applications and via phone, mail or e-mail, or (d) otherwise voluntarily providing us with your Customer Identifiable Information.
                </p>

                <p>
                    You may opt-out of receiving special offers and related communications sent by or on behalf of Tops, by (1) adjusting the user preferences on your www.TopsMarkets.com. profile or (2) contacting Tops Consumer Relations, toll-free, at 1-800-522-2522. Please note that you may still receive weekly ads, other mailings or communications sent without the use of your Customer Identifiable Information.
                </p>

                <p>
                    You may prohibit your Customer Identifiable Information from being shared with marketing service providers and/or third party marketing partners by contacting Tops Consumer Relations toll-free at 1-800-522-2522
                </p>

                <p>
                    You may altogether remove Customer Identifiable Information from your Tops account, account history, and you may prohibit third party data sharing by contacting Tops Consumer Relations toll-free at 1-800-522-2522. No Customer Identifiable Information will be stored if you shop without your Tops BonusPlus®. Please keep in mind that by removing your information or shopping without your Tops BonusPlus®, you may no longer be notified of product recalls and you may not be eligible for Tops BonusPlus® savings, rewards and other related benefits. Also, if information for one member of a household is removed, then Customer Identifiable Information for all members sharing the same Tops BonusPlus® will also be removed.
                </p>

                <h2>What measures have been designed to safeguard Customer Identifiable Information?</h2>

                <p>
                    Protecting Customer Identifiable Information is a priority for Tops. Our employee Code of Ethics and this Privacy Policy emphasize its importance. Tops employees who violate these policies and procedures are subject to disciplinary action. Marketing service providers and other third parties that receive Customer Identifiable Information are required to preserve the confidentiality of that information under the terms of our contracts with them. Tops also maintains physical, electronic and procedural safeguards that meet or exceed industry standards that are designed to keep unauthorized persons from accessing Customer Identifiable Information stored on its systems.
                </p>

                <h2>Tracking/Third-Party Advertisers</h2>
                <p>We may use third-party advertising companies to display advertisements regarding goods and services that may be of interest to you when you access and use the Services, based on information relating to your access to and use of the Services and other online services. To do so, these companies may place or recognize a unique cookie on your browser (including through the use of pixel tags). If you would like more information about this practice and to learn about your choices in connection with it, please visit <a href="https://www.aboutads.info/choices" target="_blank">www.aboutads.info/choices</a>.</p>
                <p>We may use analytics providers, such as Google Analytics (more information available at <a href="https://www.google.com/policies/privacy/partners/" target="_blank">www.google.com/policies/privacy/partners/</a>) that use cookies, pixel tags and other, similar technologies to collect information about your use of the Services and your use of other websites or online services.</p>

                <h2>Will the Privacy Policy change?</h2>

                <p>
                    Tops may modify this Privacy Policy from time to time, and any changes will become effective when posted on our website. The most recent Privacy Policy can be viewed at <a href="https://www.TopsMarkets.com" target="_blank">www.TopsMarkets.com</a>. The date of the last revision is noted at the end of this Privacy Policy.
                </p>

                <p>
                    If you have further questions, please contact Tops Consumer Relations, toll-free at 1-800-522-2522, or click "Contact Us" from our website.
                </p>

                <p>Last Revised: February 2022</p>


            </div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "privacy",
		data() {
			return {};
		},
		mounted() {
			$(".main-privacy a").attr({ target: "_blank", rel: "noopener" });
		}
	};
</script>

<style lang="scss" scoped>
// h2 {
//     @include h4;
// }
</style>
