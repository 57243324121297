<template>
    <a v-if="showAndroid" href="#" target="_blank" rel="noopener"><img
        class="w-100 img-fluid" src="../assets/images/badge-googleplay.svg" alt="Get it on Google Play"></a>

    <a v-else-if="showiOS" href="#" target="_blank" rel="noopener"><img
        class="w-100 img-fluid" src="../assets/images/badge-app-store.svg" alt="Download on the App Store"></a>
</template>

<script>
export default {
    name: "btn-download-app",
    props: {
        ios:     Boolean,
        android: Boolean,
        autodetect:Boolean
    },
    computed:{
        showAndroid(){
            return this.android || this.autodetect && !this.iOS;
        },
        showiOS(){
            return this.ios || this.autodetect && this.iOS;
        },
        // you could also use the npm package, platform
        iOS() {
            return [
                    'iPad Simulator',
                    'iPhone Simulator',
                    'iPod Simulator',
                    'iPad',
                    'iPhone',
                    'iPod'
                ].includes(navigator.platform)
                // iPad on iOS 13 detection
                || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
        }
    }
}
</script>

<style scoped>

</style>
