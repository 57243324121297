<template>
	<div class="container container-wide h-100">

		<div class="row align-items-center mt-100">
			<div class="col-lg-6">
				<img src="../assets/images/reach-for-the-stars.png" alt="TOPS Monopoly Rip It! Win It! Reach For The Stars!" class="img-fluid">
			</div>
			<div class="col-lg-6 text-center">
				<img src="../assets/images/game-prize.png" class="img-fluid my-3" alt="Win $20,000 in free food and fuel, plus epic outdoor dream kitchen, skygazing trip to NYC's planetarium, and so much more!">

                <div v-if="$store.get('auth/showGamePreregistrationMessage')" class="alert bg-gradient-grey h6">
				The promotion begins March 17th
                </div>
                <div v-else>
                    <a class="btn btn-primary btn-block my-3 rounded" :href="registerUrl">Create a TopsMarkets.com Account</a>

                    <router-link class="btn btn-primary btn-block my-3 rounded" :to="{ name: 'returning-player' }" >Login with your TopsMarkets.com Account</router-link>
                </div>

			</div>
		</div>

		<div class="position-absolute mx-n1">
			<tracker v-if="$config.useTrackers" name="home"></tracker>
		</div>
	</div>
</template>

<script>
	import brands from "../defaults/brands";
	import tracker from "@/components/trackers/General";

	export default {
		name: "home",
		components: { tracker },
		data() {
			return {
				watchConnectivityState: false,
				classname: "I am a name to get classified",
			};
		},
		computed: {
		    useApiToLogIn(){
		      return true;
            },
			brands() {
				return brands;
			},
            isApp(){
			    return process.env.VUE_APP_APP_VERSION;
            },
            loginUrl(){
                return this.$config.hostStoreUrl + '/Card_mod/Card_Login_Email_S.las?fFWD_URL=/Monopoly/Play/';
            },

            registerUrl(){
			    // following signup, we get transferred to the /Play/ page, which should then redirect us back to the website
                // inside the mobile app, we will detect that the inAppBrowser was directed to the website, strip the token out of the URL,
                // and use it to authenticate the current session
			    // return this.$config.hostStoreUrl +  '/card_mod/Card_Login_Stub.las?-token.signup=Reg&fFWD_URL=/Monopoly/Play/';
                return this.$config.hostRegistrationUrl;
            }
		},
        methods:  {
            register() {
                // this may be completely unnecessary, but it existed in SPW code
                this.$EventBus.$when("deviceready", this.beginRegister);
            },
            beginLoginOrRegister(externalSiteUrl) {
                let ref = cordova.InAppBrowser.open(
                    externalSiteUrl,
                    "_blank",
                    "location=no,hardwareback=no,zoom=no"
                );

                let                    returnedToSPW   = false;

                ref.addEventListener('exit', function () {

                });

                // Every time a new page loads, I want to know about it
                let handler = (e) => {
                    if (returnedToSPW) {
                        return;
                    }

                    let domain = e.url.match(/^https?:\/\/([^\/?]+)(?:.*)?$/);
                    if (domain) {
                        domain = domain[1];
                    } else {
                        domain = '';
                    }

                    if (/topsripitwinit(?:codes)?\.com$|^\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}(?::8081)?$/.test(domain)) {
                        returnedToSPW = true;
                        ref.close();
                        // extract the token from the URL we were returned to,
                        // remember it, and re-initialize the authentication
                        let parsedUrl = new URL(e.url);
                        let eToken = parsedUrl.searchParams.get('sessionid');
                        this.$log.info('Return URL =', parsedUrl.href);
                        this.$log.info('eToken =', eToken );
                        // prefixing the token with * informs the server this is a temporary token
                        this.$store.set('auth/token', '*'+eToken);
                        this.$store.dispatch('auth/refreshUser').then(() => {
                            if (this.$store.get('auth/isLoggedIn')) {
                                this.$router.replace({name: 'enter-code'});
                            } else {
                                // todo something went wrong. we should report this error back to the server
                                this.$router.replace({name: 'returning-player'});
                                // this.$modalService.create('errorLoggingIn');
                            }
                        });
                    }
                };

                ref.addEventListener("loadstart", handler);
                // ref.addEventListener("loadstop", handler);
            }
        }
    };
</script>

<style lang="scss" scoped>

</style>
