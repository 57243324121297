<template>
	<div class="row align-items-stretch">
		<div v-for="col in cols" :key="col" class="col my-3" v-if="showColumn(col)">
            <!-- History -->
            <div v-if="col === 'history-left'" class="bg-white p-4 p-lg-5 text-left h-100 d-flex align-items-center">
                <router-link :to="{ name: 'code-history' }" class="text-decoration-none d-block">
                    <p class="h6">Code History</p>
                    <p class="font-weight-normal">Check your Code History to see what you've entered so far.</p>
                    <div class="btn btn-primary">Code History</div>
                </router-link>
            </div>

            <!-- Winners -->
			<div v-if="col === 'winners' && hasWinners" class="bg-white p-4 p-lg-5 text-center h-100">
				<router-link :to="{ path: '/winners'}" class="text-decoration-none d-block">
					<img src="../assets/images/winner.png" alt="" role="presentation" class="img-fluid mb-4">
				<p class="h6">The Winners</p>
				</router-link>
				Take a look at the current winners!
			</div>

			<!-- FAQ -->
			<div v-if="col === 'faq'" class="bg-white p-4 p-lg-5 text-center h-100">
				<router-link :to="{ name: 'faq' }" class="text-decoration-none d-block">
					<img src="../assets/images/faq.png" alt="" role="presentation" class="img-fluid mb-4">
				<p class="h6">Have Questions?</p>
				</router-link>
				Check our FAQ page for answers.
			</div>

		</div>
	</div>
</template>

<script>
	export default {
		props: {
			cols: {
				type: Array,
				validation(value) {
					return value.length == 2;
				},
			},
		},
        computed: {
		    hasWinners(){
                let pages = this.$store.get('auth/sessionData@pages');
                if (!pages)
                    return false;
                let found = false;
                pages.forEach(value=>{
                    if (value.name === 'winners'){
                        found=true;
                    }
                })
                return found;
            },
            associate() {
                return /employee|associate/i.test(this.$store.get('auth/currentUserType'));
            }
        },
        methods:  {
            showColumn(col) {
                if (col==='winners' && !this.hasWinners){
                    return false;
                }
                return true;
            }
        }
    };
</script>

<style lang="scss" scoped>
</style>
