// Switched to store.js 10/17/2017
// deprecated?
import store from 'store';
import Raven from 'raven-js';

function setStorage(id, value, cb) {
	store.set(id, value);
	if (typeof cb === 'function') {
		let func;
		if (process.env.VUE_APP_APP_VERSION) {
			func = Raven.wrap(function() {
				cb(true, false);
			});
		}
		else {
			func = function() {
				cb(true, false);
			};
		}

		setTimeout(func, 0);
	}
}

function getStorage(id, def, cb) {
	let val = store.get(id);
	if (val === null || val === undefined)
		val = def;

	if (typeof cb === 'function') {
		let func;
		if (process.env.VUE_APP_APP_VERSION) {
			func = Raven.wrap(function() {
				cb(val, false);
			});
		}
		else {
			func = function() {
				cb(val, false);
			};
		}

		setTimeout(func, 0);
	}
}

export { setStorage, getStorage, store }
