<template>
	<ul class="navbar-nav flex-xl-row h-100 pt-5 pt-lg-0">
		<template v-for="(route, ndx) in items()">
			<li :key="ndx" :class="isActive(route.path)" class="nav-item">
				<router-link class="nav-link" :to="route.abspath || {name: route.name}" @click.native="$emit('close')" exact>{{route.title}}</router-link>
			</li>
		</template>
	</ul>
</template>

<script>
	import router from "../router";

	export default {
		name: "app-nav",
		data() {
			return {};
		},

		methods: {
			items() {
				var res = [],
					addToMenu;

				for (var i = 0; i < router.options.routes.length; i++) {
					let route = router.options.routes[i];
					if (!route.meta || !route.meta.menuTitle) {
						continue;
					}

					addToMenu = true;

					if (route.meta) {
						if (typeof route.meta.inMenu === "function") {
							addToMenu = route.meta.inMenu.call(this);
						} else if (typeof route.meta.inMenu !== "undefined") {
							addToMenu = route.meta.inMenu;
						}
						if (typeof route.meta.loggedIn !== "undefined") {
							addToMenu =
								addToMenu &&
								(route.meta.loggedIn
									? this.$store.get("auth/isLoggedIn")
									: this.$store.get("auth/isNotLoggedIn"));
						}
					}

					if (addToMenu) {
						res.push({
							name: route.name,
							path: route.path,
							title: route.meta.menuTitle,
							sort: (route.meta && route.meta.sort) || 0,
						});
					}
				}

				// add in external pages (server defined content)
				let pages = this.$store.get("auth/sessionData@pages");
				if (pages) {
					res.push(...pages);
				}

				res.sort((a, b) => {
					return a.sort - b.sort;
				});

				return res;
			},

			isActive(path) {
				// if (this.$route.path.indexOf(path) === 0)
				if (this.$route.path === path) {
					return "active";
				}
			},

			closeMenu() {
				$("body").removeClass("menu-open");
			},

			getLang() {
				return this.global.lang;
			},

			toggleLang() {
				if (this.global.lang === "en") {
					this.global.lang = "es";
				} else {
					this.global.lang = "en";
				}
			},
		},
	};
</script>

<style lang="scss">
	/* .navbar-toggler,
			.navbar-brand {
				display: none;
			} */
	.navbar-toggler {
		z-index: 1;
	}
	.navbar-nav {
		.nav-link {
			display: block;
			line-height: 1;
			padding: 10px 20px!important;
			@include nav-item;
			font-size: $size22;
			text-decoration: none;
			transition: background 250ms;
			@include fontspecial;
            &.router-link-active,
            &:focus,
            &:active,
            &:hover {
                @include nav-item-active;
            }
		}
	}

	.nav {
		flex-direction: row;
	}

	@media (max-width: $collapse - 1) {
		.navbar-toggler {
			display: block;
			z-index: 2;
		}
		.navbar-toggler {
			cursor: pointer;
			border-radius: 0;
			border: 0;
			padding: 11px 10px 12px;
			right: 0;
			transition: background 250ms;
			&:hover,
			&:focus,
			&:active {
				// background-color: #113157;
				// .employee & {
				// 	background-color: $employeeBackground;
				// }
			}
			&[aria-expanded="true"] {
				.icon-bar {
					opacity: 0;
				}
				.icon-bar-x {
					opacity: 1;
					transform: rotate(45deg);
					transition: 250ms 150ms;
				}
				.icon-bar-sneaky {
					transform: rotate(-45deg);
				}
			}
			.icon-bar {
				position: relative;
				background-color: $light;
				width: 32px;
				height: 3px;
				opacity: 1;
				transform: rotate(0deg);
				transition: 250ms;
				top: 0;
				display: block;
				margin-top: 4px;
				&:first-child {
					margin-top: 0;
				}
				&.icon-bar-sneaky {
					margin-top: -3px;
				}
				&:last-child {
					margin-bottom: 1px;
				}
			}
		}
		.navbar {
			justify-content: space-between;
			.main-menu {
				@include navbar;
				text-align: center;
				position: fixed;
				top: 95px;
				right: -100%;
				bottom: 0;
				overflow: auto;
				width: 100%;
				transition: opacity 250ms, right 0ms 250ms;
				opacity: 0;
				align-items: center;
				justify-content: center;
				z-index: 1;
				&.menu-open {
					display: flex;
					right: 0;
					opacity: 1;
					transition: opacity 500ms;
					.nav-link {
						display: block;
						width: 100%;
                        font-size: 1.5rem;
                        line-height: 2.5;
					}
				}
			}
			.nav {
				flex-direction: column;
			}
			.nav-item {
				display: block;
				&.active {
					a {
						background-color: transparent;
					}
				}
			}
		}
	}
</style>
