// import store from 'store2';
import {make} from 'vuex-pathify';
import appStorage from '../../utils/nativestorage';
import Raven from 'raven-js';
import api from '../../utils/axios'
import Vue from 'vue';
import {get} from 'lodash'

let GET = {};
const MOCK_USER =  false;

// {
// 	canEnterCodes:true,
// 	user: {
// 		id:    1,
// 		email: 'thomas@digitalattic.com',
// 		data:{}
// 	}
// };

location
	.search
	.substr(1)
	.split('&')
	.filter(v => v)
	.forEach(v => {
		let parts = v.split('=');
		GET[parts[0]] = decodeURI((parts[1] || '').replace(/\+/g, ' '));
	});

export default {
	namespaced: true,

	state:     {
		user:       null,
		token:      null,
		session_id: null,
		ready:      false,
		eToken:     null,

		// for development testing purposes only
		lastPlayedCode: null,

		// notification messages for the user
		messages: [],

		// the sweepstakes messaging that appears in the banner of the site
		sweeps: [],

		// the entire response returned from the most recent `GET /user` request
		sessionData: {}
	},
	mutations: {
		...make.mutations(['user', 'ready', 'messages', 'sessionData', 'lastPlayedCode']),

		SET_MATCH_3_GAME(state, data) {
			state.user.playMatch3Game = data;
		},

		SET_USER(state, data) {
			state.sessionData = data;
			state.messages = (data && data.messages) || [];
			state.sweeps = (data && data.sweeps) || [];
			state.user = (data && data.user) || null;
			Vue.$log.info('Auth: SET_USER login_token =', state.user && state.user.login_token);
			if (state.user) {
				Vue.$log.info('Auth: SET_USER session_id =', state.user.session_id);
				Vue.$log.info('Auth: SET_USER X-AUTHKEY =', state.user.login_token);
				Vue.$log.info('Auth: eToken =', state.user.ephemeral_login_token);

				// do not attempt to change the session_id here, it has already been set prior
				// to this when we first identified the current user:
				// store.set('session_id', state.session_id);
				// a user is identified, so let's save the re-auth token to localstorage so we don't forget it later
				appStorage.setItem('session_id', state.user.session_id);
				appStorage.setItem('userAuthToken', state.user.login_token);
				state.token = state.user.login_token;
				state.session_id = state.user.session_id;
				state.eToken = state.user.ephemeral_login_token;
			} else {
				Vue.$log.info('Auth: SET_USER No user, no session_id, no X-AUTHKEY');
				appStorage.removeItem('userAuthToken');
				appStorage.removeItem('session_id');
				state.token = null;
				state.session_id = null;
				state.eToken = null;
			}
		},

		/**
		 * The token is used to re-establish identity when the primary PHPSESID variable, or other session
		 * identification value does not have an identity established.
		 * @param state
		 * @param token
		 * @constructor
		 */
		SET_TOKEN(state, token) {
			Vue.$log.info('Auth: SET_TOKEN =', token);
			appStorage.setItem('userAuthToken', token);
			state.token = token;
		},

		/**
		 * @param state
		 * @param id
		 * @constructor
		 */
		SET_SESSION_ID(state, id) {
			Vue.$log.info('Auth: SET_SESSION_ID =', id);
			// ignore invalid session id values
			if (!id || id.length < 6) {
				appStorage.setItem('session_id', '');
				state.session_id = null;
			} else {
				appStorage.setItem('session_id', id);
				state.session_id = id;
			}
		},

		SET_MISSING_PHONE_NUMBER(state, val) {
			if (state.user) {
				state.user.missing_phone_number = val;
			}
		},

		SET_ADD_MORE_TOKENS(state, extraTokens) {
			if (state.user && extraTokens) {
				let qty = parseInt(extraTokens, 10);
				state.user.data.tokens += qty;
			}
		},

		SET_TOKENS_AVAILABLE(state, qty){
			state.user.data.tokens = parseInt(qty, 10)
		}
	},
	actions:   {
		loadStoredValues({
			state,
			commit
		}) {
			return new Promise((resolve, reject) => {
				let domainParts = location.hostname.match(/^(qa|www)\.topsripitwinit(codes)?\.(com|local)$/);
				let usingLoggedInDomain = false;
				if (domainParts) {
					usingLoggedInDomain = !!domainParts[2];
				}
				Vue.$log.info('Auth: loadStoredValues');
				const p1 = appStorage.getItem('userAuthToken');
				const p2 = appStorage.getItem('session_id');
				Promise.all([p1, p2]).then(values => {
					commit('SET_TOKEN', values[0]);
					commit('SET_SESSION_ID', values[1]);
					Vue.$log.info('Auth: loadStoredValues - values=', values);
					// ignore sessionid parameter unless on topsripitwinitcodes.com
					if (GET['sessionid'] && usingLoggedInDomain) {
						let eToken = GET['sessionid'];
						Vue.$log.info('Auth: loadStoredValues - using ephemeral token for identification token', eToken);
						// treat this as an eToken and not a regular login_token
						commit('SET_TOKEN', '*' + eToken);
					}
					resolve();
				});
			});
		},
		/**
		 * todo error handling .. if no response from server, should we set a global warning message to be displayed to user?
		 * @param state
		 * @param commit
		 * @returns {Promise<unknown>}
		 */
		refreshUser({
			state,
			commit,
			dispatch
		}) {
			return new Promise((resolve, reject) => {
				// Refresh user state
				if (MOCK_USER) {
					commit('SET_READY', true);
					commit('SET_USER', MOCK_USER);
					resolve(state.user);
					return;
				}
				dispatch('loadStoredValues').then(() => {
					// if (state.ready) {
					// 	// if we have it, send the persistent login token with our identification request -
					// 	// if our typical session id is invalid, the server will generate a new one and log in the user
					// 	let identifyUser = {
					// 		method:  'get',
					// 		url:     '/user',
					// 		headers: {}
					// 	}
					// 	if (/local|qa/.test(location.hostname) && typeof theDate !== 'undefined') {
					// 		identifyUser.url += '?date=' + theDate;
					// 	}
					// 	if (state.token && state.token.length > 8) {
					// 		Vue.$log.info(`Auth: refreshUser 2 - using token`, state.token);
					// 		identifyUser.headers['X-AUTHKEY'] = state.token;
					// 	} else {
					// 		Vue.$log.info(`Auth: refreshUser 2 - no token is set`);
					// 		commit('SET_TOKEN', '');
					// 	}
					// 	Vue.$log.info('Auth: refreshUser 2 - Checking identity');
					// 	api
					// 		.request(identifyUser)
					// 		.then((response) => {
					// 			commit('SET_USER', response.data);
					// 			resolve(state.user);
					// 		})
					// 		.catch((error) => {
					// 			reject();
					// 		});
					// } else {

						// if we have it, send the persistent login token with our identification request -
						// if case our session id is expired, the server will use the X-AUTHKEY header
						// to login to the session identified by X-AUTH
						// (effectively reestablishing the session ID)
						let identifyUser = {
							method:  'get',
							url:     '/user',
							headers: {}
						};

						// if we have a login token, let's login the user now
						if (process.env.VUE_APP_APP_VERSION && window.legacyToken) {
							identifyUser = {
								method:'post',
								url:'/user/login',
								data:{
									token:window.legacyToken
								}
							}
						}
						if (/local|qa/.test(location.hostname) && typeof theDate !== 'undefined') {
							identifyUser.url += '?date=' + theDate;
						}
						if (state.token && state.token.length > 8) {
							Vue.$log.info(`Auth: refreshUser 1 - X-AUTHKEY=`, state.token);
							identifyUser.headers['X-AUTHKEY'] = state.token;
						} else {
							Vue.$log.info(`Auth: refreshUser 1 - X-AUTHEKY unset`);
							commit('SET_TOKEN', '');
						}

						Vue.$log.info('Auth: refreshUser 1 - Checking identity',identifyUser);
						api
							.request(identifyUser)
							.then((response) => {
								if (response.data) {
									commit('SET_USER', response.data);
								} else {
									commit('SET_TOKEN', '');
								}
							}, failed => {
								Vue.$log.info('Auth: refreshUser 1 - server error', failed)
							})
							.catch((a) => a)
							.then(() => {
								// Finally
								commit('SET_READY', true);
								resolve(state.user);
							});

				});
			});
		},

		logoutUser({commit}) {
			return new Promise((resolve) => {
				Vue.$log.info('Auth: logoutUser');
				api.get('/user/logout').then((response) => {
					if (response.data) {
						commit('SET_USER', response.data);
					} else {
						commit('SET_TOKEN', '');
					}
					// these 3 calls will update the localStorage and VueX state
					// commit('SET_SESSION_ID', '');
					// commit('SET_TOKEN', '');
					// commit('SET_USER', null);
					Vue.$log.info('Auth: logoutUser complete');
					resolve();
				}, (fail) => {
					resolve();
				});
			});
		},
	},
	getters:   {
		...make.getters(['user', 'ready', 'session_id', 'eToken', 'notices', 'messages', 'sweeps', 'lastPlayedCode']),

		match3Game(state) {
			let user = state.user;
			if (!user) {
				return null;
			}
			return user.playMatch3Game;
		},

		defaultPlayerLandingPageName(state) {
			return get(state.sessionData, 'landingPage', 'enter-code');
		},

		/**
		 *  show remaining tokens if no errors
		 */
		showRemainingTokens(state) {
			let user = state.user;
			if (!user) {
				return false;
			}
			return !user.must_agree_to_j4u_terms && !user.errors;
		},
		allowCodeHistory(state){
			let user = state.user;
			if (!user) {
				return false;
			}
			let canViewHistory = get(state.sessionData, 'canViewHistory', true);
			return !user.must_agree_to_j4u_terms && !user.errors   && canViewHistory;
		},
        allowWinners(state) {
            let user = state.user;
            if (!user) {
                return false;
            }
            return state.user.type == 'Customer';
        },
		allowSpendTokens(state) {
			let user = state.user;
			if (!user) {
				return false;
			}
			let canSpendTokens = get(state.sessionData, 'canRedeemTokens', true);
			return !user.must_agree_to_j4u_terms && !user.errors  && canSpendTokens;
		},
		allowEnterCodes(state) {
			let user = state.user;
			if (!user) {
				return false;
			}
			let canEnterCodes = get(state.sessionData, 'canEnterCodes', true);
			return !user.must_agree_to_j4u_terms && !user.errors   && canEnterCodes;
		},
		showGamePreregistrationMessage(state){
			console.log(state.sessionData);
			let user = state.user;
			if (!user) {
				return false;
			}
			if (get(state.sessionData, 'canViewHistory', true)||get(state.sessionData, 'canEnterCodes', true)){
				return false;
			}
			return true;
		},

		/**
		 * Logged in people can not access login/register buttons or pages.
		 * Server also controls whether current session can login/register.
		 * see UserController.php
		 */
		allowLoginOrRegister(state){
			let user = state.user;
			if (user) {
				return false;
			}
			return get(state.sessionData, 'canLogin', false)||get(state.sessionData, 'canRegister', false);
		},
		allowLogin(state){
			let user = state.user;
			if (user) {
				return false;
			}
			return get(state.sessionData, 'canLogin', false);
		},
		allowRegister(state){
			let user = state.user;
			if (user) {
				return false;
			}
			return get(state.sessionData, 'canRegister', false);
		},

		currentUserType(state) {
			return state.user ? state.user.type : 'Customer';
		},

		currentUserEmail(state) {
			return state.user ? state.user.email : '';
		},

		currentUserPhone(state) {
			return state.user ? state.user.phone : '';
		},
		isLoggedIn(state) {
			return !!state.user;
		},

		/**
		 * united, safeway, mymixx
		 * @param state
		 * @returns {*}
		 */
		rewardsProgram(state) {
			if (!state.user) {
				return null;
			}
			let data = state.user.data;
			return data.provider || 'safeway';
		},

		rewardsProgramName(state) {
			if (!state.user || !state.user.data) {
				return null;
			}
			return state.user.data.rewardsProgramName;
		},
		isTester(state) {
			return state.user && state.user.data && state.user.data.isTester;
		},

		isNotLoggedIn(state) {
			return !state.user;
		},

		missingPhoneNumber(state) {
			if (!state.user) {
				return false;
			}
			return !!state.user.missing_phone_number;
		},

		mustAgreeToJ4UTerms(state) {
			if (!state.user) {
				return false;
			}
			return !!state.user.must_agree_to_j4u_terms;
		},

		mustUpdateProfile(state) {
			if (!state.user || typeof state.user.data === 'undefined') {
				return false;
			}
			//|| !(state.user.data.store_number > 0)
			return !!state.user.must_agree_to_j4u_terms ;
		},

		userData(state) {
			if (!state.user) {
				return {};
			}
			return state.user.data;
		},
		banner(state) {
			if (!state.user) {
				return null;
			}
			return state.user.data.banner;
		},
		numberOfTokens(state) {
			if (!state.user) {
				return null;
			}
			return parseInt(state.user.data.tokens);
		},
		tokensAcquired(state) {
			if (!state.user) {
				return null;
			}
			let x = parseInt(state.user.data.tokens);
			if (x == 1) {
				return '1 Token';
			}
			return `${x} Tokens`;
		},


		showFuelRewards(state) {
			// technically this should never happen
			if (!state.user || !state.user.data) {
				return false;
			}
			return state.user.data.fuelRewardsProgram;
		},

		editProfileUrl(state) {

		},

		warningMessage(state) {
			return state.sessionData && state.sessionData.error;
		}
	}
}
