<template>
    <div class="container-fluid">
        <h1 class="text-center page-title text-light">Player Login</h1>
		<div class="pt-4 pt-xl-5">
			<div class="container bg-white p-4 p-xl-5 rounded">
                <div class="row justify-content-center">
                    <div class="col-lg-8">
                        <div class="font-weight-bold text-danger" v-for="(message) in $store.get('auth/messages')" v-html="message"></div>

                        <p>Log in using your TopsMarkets.com account information.</p>

                        <FormulateForm name="loginForm"
                                       @submit="submit"
                                       :schema="loginForm"
                                       v-model="formData"
                                       #default="{ isLoading, hasErrors }"
                                       :values="testData"
                                       :form-errors="formErrors">
                            <recaptcha3 :siteKey="$config.useCaptcha && $config.recaptcha.siteKey"></recaptcha3>
                            <button class="btn btn-primary btn-rounded purple-gradient-vertical px-5" ref="btnSubmit" data-style="zoom-out">Login</button>
                            <!--                            <button class="btn btn-outline-danger" @click.prevent="formData = testData">test</button>-->
                        </FormulateForm>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Recaptcha3 from '../components/Recaptcha3';

export default {
    name:       "returning-player",
    components: {Recaptcha3},
    data() {
        return {
            testData: {
                email:    'thomas@digitalattic.com',
                password: 'digital',
            },
            //https://vueformulate.com/guide/validation/#customize-validation-messages
            loginForm:  [
                {
                    name:       'email',
                    label:      'Email',
                    type:       'text',
                    validation: 'email',
                    value:      ''
                },
                {
                    name:       'password',
                    label:      'Password',
                    type:       'password',
                    validation: 'required',
                    value:      ''
                }
            ],
            formErrors: [],
            formData:   null
        }
    },
    methods: {
        async submit(data) {
            this.busyButton(this.$refs.btnSubmit, true, 'login');
            if (this.$recaptcha) {
                data['captcha'] = await this.$recaptcha('login');
            }

            let user = await this.$http.post('user/login', data).catch(({response}) => {
                this.busyButton(this.$refs.btnSubmit, false, 'login');
                this.formErrors = ["Your request could not be processed at this time. Please try again later."];
                if (response.data.error) {
                    this.formErrors = [response.data.error.errorMessage];
                }
            });
            if (user) {
                this.$store.set('auth/user', user.data);
                this.$router.push({name: 'enter-code'});
            }
        },
    }
};
</script>
